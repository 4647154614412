import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";

import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";

const Wrapper = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const BankItem = styled.div`
  cursor: pointer;
  padding: 1.25rem 10px;
  align-items: center;

  border-bottom: 1px solid #efefef;

  &:hover {
    background: #efefef;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 0;
    margin-top: 0 !important;
    border: none;
    outline: none;
    padding: 0.8rem 1rem 0.6rem;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;
  }
`;

type Props = {
  officeList: any;
  chosenCity: any;
  chooseOffice: (bank: any) => void;
};

const OfficeSearch = ({ officeList, chosenCity, chooseOffice }: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(
    officeList?.filter((item: any) => item.cityName === chosenCity.cityName)
  );

  useEffect(() => {
    if (searchValue) {
      setData(
        officeList?.filter((office: any) => {
          return office.officeName
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        })
      );
    }else {
      setData(officeList);
    }
  }, [searchValue]);

  return (
    <Wrapper className="animate-fadeIn">
      <FlexWrapper align="center">
        <SearchWrapper>
          <span>
            <input
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("sendtransfer.searchoffices")}
            />
          </span>

          <button>
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>
      <Title mt="1rem" className="uppercase" weight="600" ls="0px">
        {t("banktransfer.offices")}
      </Title>
      <Divider />

      <Scrollbars style={{ height: 470 }}>
        {data.map((office: any) => (
          <BankItem onClick={() => chooseOffice(office)}>
            <Text
              weight="500"
              color={"var(--text-color)"}
              size="14px"
              mb="0.4rem"
            >
              {office.officeName} {office.address}
            </Text>

            <Text>
              {t("verify.working")}: {office.workingHours}
            </Text>
            <Text>
              {t("cashpickup.payoutcurrency")}: {office.workingCurrency}
            </Text>
          </BankItem>
        ))}

        {!data?.length && (
          <div
            className="flex justify-center font-semibold"
            style={{ padding: "60px 20px 60px" }}
          >
            {t("banktransfer.nothingfound")}
          </div>
        )}
      </Scrollbars>
    </Wrapper>
  );
};

export default OfficeSearch;
