import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Title from "../../../../components/Text/title";
import FlexWrapper from "../../../../components/FlexWrapper";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import { userSelector } from "../../../../redux/selectors";
import EmailChangeModal from "./EmailChangeModal";
import {
  changeEmailConfirm,
  changeEmailInitiate,
  changeEmailVerify,
} from "../../../../services/Services";
import TwoFactorModal from "../../../../components/TwoFactor/Index";
import { getRefreshToken, saveStorageObject } from "../../../../utils/storage";
import Phone from "./Phone";

const AccountInfo = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const user = useSelector(userSelector);
  const [emailOpen, setEmailOpen] = useState(false);
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const [newMail, setNewMail] = useState("");
  const [mode, setMode] = useState("initial");
  const [existingMail, setExistingMail] = useState(user?.email);

  useEffect(() => {
    setExistingMail(user?.email);
  }, [user]);

  const handleEmailInitiate = (email: string, password: string) => {
    changeEmailInitiate(user.userId, email.trim(), password)
      .then((res) => {
        setNewMail(email);

        setEmailOpen(false);
        setIsTwofactorOpen(true);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleEmailVerify = (pin: any) => {
    changeEmailVerify(user.userId, pin, newMail.trim())
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setIsTwofactorOpen(false);
        setMode("final");
        setTimeout(() => {
          setIsTwofactorOpen(true);
        }, 400);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handleEmailConfirm = (pin: any) => {
    // @ts-ignore
    changeEmailConfirm(user.userId, getRefreshToken(), pin)
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });

        setExistingMail(newMail);
        const newUser = user;
        user.clientPhone = newMail;
        saveStorageObject("user", newUser);
        setIsTwofactorOpen(false);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return (
    <>
      <div className="bg-white p-6 rounded-lg border-solid border-[1px] border-[#EBEBEB]">
        <Title weight="500">{t("profile.accountinfo")}</Title>
        <Text color="#56575B">{t("profile.manage")}</Text>
        <Divider width="100%" mt="1.5rem" mb="1.5rem" />

        <FlexWrapper mb="1rem">
          <Text color="#56575B">{t("common.username")}: </Text>
          <Text ml="0.35rem" color="#171922">
            {user.userName}
          </Text>
        </FlexWrapper>
        <FlexWrapper
          mb="1rem"
          justify="space-between"
          mobileDirection="column"
          mobileAlign="flex-start"
          gap="8px"
        >
          <FlexWrapper mb="0">
            <Text color="#56575B">{t("register.email")}: </Text>
            <Text ml="0.35rem" color="#171922">
              {existingMail}
            </Text>
          </FlexWrapper>
          <div onClick={() => setEmailOpen(true)}>
            <Text
              color="var(--red)"
              weight="600"
              className="uppercase pointer hover:underline"
            >
              {t("profile.changeemail")}
            </Text>
          </div>
        </FlexWrapper>
        <Phone />
      </div>

      <EmailChangeModal
        isOpen={emailOpen}
        onClose={() => setEmailOpen(false)}
        onConfirm={handleEmailInitiate}
      />

      {isTwofactorOpen && (
        <TwoFactorModal
          mode={mode === "final" ? "email" : "mobile"}
          displayCode={mode === "final" ? newMail : user.clientPhone}
          isOpen={isTwofactorOpen}
          // onResend={handleEmailInitiate}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) =>
            mode === "final" ? handleEmailConfirm(pin) : handleEmailVerify(pin)
          }
        />
      )}
    </>
  );
};

export default AccountInfo;
