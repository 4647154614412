import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Scrollbars } from "rc-scrollbars";

import { TemplateItem, TemplateWrapper } from "./Templates.styled";
import { checkService, getBillTemplates } from "../../../../services/Services";
import Title from "../../../../components/Text/title";
import Divider from "../../../../components/Divider";
import Text from "../../../../components/Text/text";
import FlexWrapper from "../../../../components/FlexWrapper";
import AccountsModal from "../../../../components/AccountsModal";
import { setBillTransferData } from "../../../../redux/billSlice";
import { setLoading } from "../../../../redux/commonSlice";
import { userIdSelector } from "../../../../redux/selectors";
import {
  useDimension,
  useDimensionType,
} from "../../../../hooks/useWindowSize";
import Img from "../../../../components/Img";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { useToasts } from "react-toast-notifications";

type BillTemplateProps = {
  AbonentName: string;
  CategoryId: string;
  CheckInfo: string;
  Debt: number | null;
  Id: number;
  Parameters: any;
  ServiceId: string;
  ServiceName: string;
  SubCategoryId: string;
  TemplateName: string;
};

const Templates = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<BillTemplateProps[]>([]);
  const navigate = useNavigate();
  const [activeTemplate, setActiveTemplate] = useState<BillTemplateProps>();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";
  const isDesktop = deviceType === "desktop";
  const dimensions = useDimension();
  const { addToast } = useToasts();

  useEffect(() => {
    getBillTemplates().then((res) => setTemplates(res.data.Content));
    dispatch(setBillTransferData({ isFromTemplate: false, processes: [] }));
  }, []);

  const handleTemplateClick = (item: BillTemplateProps) => {
    setActiveTemplate(item);
    dispatch(setLoading(true));
    const { Parameters } = item;

    checkService(userId, item?.ServiceId, [
      { key: Parameters[0]?.Key, value: Parameters[0].Value },
    ])
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setBillTransferData(res.data.Content));
        dispatch(setBillTransferData({ isFromTemplate: true }));
        navigate(`/billpayments/service/${item?.ServiceId}`, {
          state: { isFromTemplate: true, activeTemplate: res.data.Content },
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const handleCheckService = (item: BillTemplateProps) => {
    dispatch(setLoading(true));
    checkService(userId, item?.ServiceId, [
      { key: item.Parameters[0].Key, value: item.Parameters[0].Value },
    ])
      .then((res) => {
        dispatch(setLoading(false));
        const newTemplate = templates.find(
          (template) => template.Id === item.Id
        );
        const updatedTemplate = {
          ...newTemplate,
          Debt: res.data.Content.Debt,
        };

        setTemplates((prevTemplates) =>
          prevTemplates.map((template: any) =>
            template.Id === item.Id ? updatedTemplate : template
          )
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  if (!templates?.length) {
    return null;
  }


  return (
    <TemplateWrapper>
      <FlexWrapper mb="0" justify="space-between">
        <Title size="12px" weight="500" mb="0.25rem" className="uppercase">
          {t("billpayments.templates")}
        </Title>
        <Text color="var(--red)" size="10px">
          <Link
            to="/billpayments/templates"
            className="underline uppercase mb-[6px] inline-block"
          >
            {t("common.showmore")}
          </Link>
        </Text>
      </FlexWrapper>

      <Divider width="100%" mb="1rem" />

      <Scrollbars
        autoHeightMin={150}
        autoHeightMax={470}
        // autoHeight={!isMobile ? true : false}
        autoHeight
        renderThumbHorizontal={(props) => (
          <div {...props} className="thumb-horizontal" />
        )}
      >
        <FlexWrapper
          gap="16px"
          style={{ flexWrap: "nowrap", whiteSpace: "nowrap", width: "150px" }}
        >
          {templates?.map((item: any) => (
            <TemplateItem
              key={item?.ServiceId}
              onClick={() => handleTemplateClick(item)}
            >
              <Text className="uppercase" mb="0" size="10px">
                {item?.ServiceName}
              </Text>

              <object
                width="45px"
                height="45px"
                data={`https://files2.mp.ge/uploads/comunalservices/${item?.ServiceId}.png`}
                type="image/png"
              >
                <Img
                  width={36}
                  style={{
                    marginLeft: 12,
                    marginBottom: 6,
                    marginTop: 12,
                  }}
                  src="/billpayments/image-fill.svg"
                  alt="fallback"
                />
              </object>
              {item?.Debt === null && item?.CheckInfo !== null ? (
                <div className="flex flex-col items-center ">
                  <Text
                    mb="0.5rem"
                    color="#E22820"
                    className="truncate"
                    style={{ wordBreak: "break-all", width: 180 }}
                  >
                    {t("billpayments.unavailable")}
                  </Text>
                  <ReplayOutlinedIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckService(item);
                    }}
                    style={{ fill: "#E22820" }}
                  />
                </div>
              ) : (
                <>
                  <Text
                    mb="0.2rem"
                    color={
                      item?.Debt > 0
                        ? "#E22820"
                        : item?.Debt < 0
                        ? "#0FBD5B"
                        : "#848688"
                    }
                    size="14px"
                  >
                    {item?.Debt > 0 && "-"}
                    {item?.Debt < 0 && "+"}
                    {item.Debt < 0
                      ? item?.Debt?.toString().replace("-", "")
                      : item?.Debt || "0.00"}
                  </Text>
                  {item?.Parameters?.length && (
                    <Text
                      size="10px"
                      lh="14px"
                      color="#56575B"
                      ta="center"
                      className="truncate max-w-full font-['Roboto']"
                    >
                      {item?.Parameters[0]?.Value}
                    </Text>
                  )}
                  <Text
                    size="10px"
                    lh="14px"
                    color="#56575B"
                    ta="center"
                    className="truncate max-w-full !font-medium"
                  >
                    {item?.TemplateName}
                  </Text>
                </>
              )}
            </TemplateItem>
          ))}
        </FlexWrapper>
      </Scrollbars>
    </TemplateWrapper>
  );
};

export default Templates;
