import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import ResultModal from "../../../components/ResultModal";
import { useNavigate } from "react-router-dom";
import { StepContainer, Wrapper } from "./Forgot.styled";

const Forgot: React.FC = () => {
  const { i18n } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [direction, setDirection] = useState<string>("");
  const [mode, setMode] = useState("");
  const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const languageKey = i18n.language;
  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  const handleContinue = () => {
    setDirection("left");
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setDirection("right");
    setStep((prev) => prev - 1);
  };

  const handlePasswordChange = () => {
    setIsResultOpen(true);
    setMode("success");
  };

  useEffect(() => {
    if (direction) {
      const timeout = setTimeout(() => setDirection(""), 100);
      return () => clearTimeout(timeout);
    }
  }, [direction]);

  return (
    <Wrapper className={fontClass}>
      <div className="max-w-[1440px] mx-auto px-4 md:px-8 w-full h-screen flex justify-center items-center">
        <div className="w-full bg-white rounded-xl overflow-hidden animate-in fade-in duration-500">
          <div className="mx-auto md:p-6 p-4 ">
            <StepContainer direction={direction} className="">
              {step === 1 && <Step1 onContinue={handleContinue} />}
              {step === 2 && (
                <Step2 onBack={handleBack} onContinue={handleContinue} />
              )}
            </StepContainer>
            {step === 3 && (
              <Step3
                onBack={handleBack}
                onContinue={handlePasswordChange}
                isResultOpen={isResultOpen}
              />
            )}
          </div>
        </div>
      </div>
      <ResultModal
        mode={mode}
        isOpen={isResultOpen}
        successTitle="reset.resulttitle"
        successText="reset.resulttext"
        onClose={() => {
          setIsResultOpen(false);
          navigate("/login");
        }}
      />
    </Wrapper>
  );
};

export default Forgot;
