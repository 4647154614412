import React from "react";
import cx from "classnames";

type TitleVariant =
  | "subtitle"
  | "body"
  | "body2"
  | "body3"
  | "number"
  | "number1"
  | "number2"
  | "btn-lg"
  | "btn-md"
  | "btn-sm"
  | "btn-underline"
  | "text-btn"
  | "overline"
  | "overline1"
  | "sm-text"
  | "xs-text";

interface TitleProps {
  variant: TitleVariant;
  children: React.ReactNode;
  className?: string;
}

const variantStyles: Record<TitleVariant, string> = {
  subtitle: "text-sm font-semibold text-[#56575B]",
  body: "text-base text-[#15151D]",
  body2: "text-sm text-[#15151D]",
  body3: "text-xs text-[#15151D]",
  number: "text-[1.25rem] font-bold text-[#15151D]",
  number1: "text-base font-bold text-[#15151D]",
  number2: "text-sm font-bold text-[#15151D]",
  "btn-lg": "text-base font-medium caps uppercase text-[#15151D]",
  "btn-md": "text-sm font-medium caps uppercase text-[#15151D]",
  "btn-sm": "text-xs font-medium caps uppercase text-[#15151D]",
  "btn-underline":
  "text-xs font-medium caps uppercase underline text-[#15151D]",
  "text-btn": "text-xs font-medium caps uppercase text-[#15151D]",
  overline: "text-sm font-medium caps uppercase text-[#15151D]",
  overline1: "text-xs font-medium caps uppercase text-[#15151D]",
  "sm-text": "text-[10px] font-medium text-[#15151D]",
  "xs-text": "text-[8px] text-[#15151D]",
};

const Text: React.FC<TitleProps> = ({ variant, children, className }) => {
  return <p className={cx(variantStyles[variant], className)}>{children}</p>;
};

export default Text;
