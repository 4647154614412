import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AccountsItem, AccountsWrapper } from "./Accounts.styled";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import {
  accountsSelector,
  userIdSelector,
  userSelector,
} from "../../../../redux/selectors";
import { Account } from "../../../home/Accounts/accounts/types";
import Img from "../../../../components/Img";
import { getCurrencySymbol } from "../../../../utils/helpers";
import Balance from "../../../../components/Balance";
import Star from "../../../../components/Icons/Star";
import FavouriteModal from "../../../home/Accounts/accounts/FavouriteModal";
import {
  GetFavouriteAccount,
  GetUserAccounts,
  SetFavouriteAccount,
  confirmDeactivation,
  initiateDeactivation,
  renameAccount,
} from "../../../../services/Services";
import DeactivationModal from "./DeactivationModal";
import { setAccounts as setAccountsFromRedux } from "../../../../redux/authSlice";
import PasswordModal from "./PasswordModal";
import TwoFactorModal from "../../../../components/TwoFactor/Index";
import NonDeactivationModal from "./NonDeactivatedModal";
import RenameModal from "./RenameModal";
import {
  Desktop,
  TabletOrMobile,
} from "../../../../components/responsive/responsive";

const Accounts = () => {
  const { t } = useTranslation();
  const accountsFromRedux = useSelector(accountsSelector);
  const [accounts, setAccounts] = useState(accountsFromRedux || []);
  const [favouriteCurrency, setFavouriteCurrency] = useState<any>();
  const [isFavouriteOpen, setIsFavouriteOpen] = useState(false);
  const { addToast } = useToasts();
  const userId = useSelector(userIdSelector);
  const [tempAccount, setTempAccount] = useState<any>();
  const [deactivateOpen, setIsDeactivateOpen] = useState<any>(false);
  const [passwordOpen, setIsPasswordOpen] = useState(false);
  const dispatch = useDispatch();
  const [isTwofactorOpen, setIsTowfactorOpen] = useState(false);
  const [nonDeactivationModal, setNonDeactivationModal] = useState(false);
  const navigate = useNavigate();
  const [renameModal, setRenameModal] = useState(false);
  const user = useSelector(userSelector);

  useEffect(() => {
    if (userId) {
      GetFavouriteAccount(userId).then((res) =>
        setFavouriteCurrency(res.data.Content?.Id)
      );
    }
  }, [userId]);

  useEffect(() => {
    if (!accountsFromRedux?.length && userId) {
      GetUserAccounts(userId)
        .then((res) => {
          setAccounts(res.data.Content);
          dispatch(setAccountsFromRedux(res.data.Content));
        })
        .catch((err) => console.log(err));
    }
  }, [accountsFromRedux?.length, accounts, dispatch, userId]);

  const setFavouriteAccountHandler = (account: any) => {
    SetFavouriteAccount(userId, tempAccount?.Id)
      .then((res) => {
        setFavouriteCurrency(tempAccount?.Id);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
          placement: "top-center",
        });
        setIsFavouriteOpen(false);
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleDeactivationInitiation = (password: string) => {
    initiateDeactivation("Password", password, userId)
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setIsTowfactorOpen(true);
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleDeactivationConfirm = (pin: string) => {
    confirmDeactivation(userId, pin).then((res) => console.log(res.data));
  };

  const handleRename = (newName: string) => {
    renameAccount(userId, tempAccount?.Id, tempAccount?.WalletId, newName)
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setRenameModal(false);
        GetUserAccounts(userId).then((res) => {
          setAccounts(res.data.Content);
          dispatch(setAccountsFromRedux(res.data.Content));
        });
      })
      .catch((err) => {
        setRenameModal(false);
        if (err.response.data.errors) {
          Object.values(err.response.data.errors).forEach((errorArray: any) => {
            errorArray.forEach((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return (
    <AccountsWrapper>
      <Title size="18px" weight="600" color="#101828">
        {t("sidebar.accounts")}
      </Title>
      <Text>{t("manage.accounttext")}</Text>
      <Divider color="#EAECF0" width="100%" mt="1rem" mb="1rem" />
      <div className="flex flex-start flex-col lg:flex-row">
        <FlexWrapper
          style={{ flex: 1, height: 310 }}
          direction="column"
          justify="space-between"
          align="flex-start"
        >
          <Title style={{ flex: 1 }} size="14px" weight="600" color="#101828">
            {t("accounts.title")}
          </Title>

          <Desktop>
            <Title
              className="pointer"
              color="#E02629"
              size="12px"
              onClick={() => setIsDeactivateOpen(true)}
            >
              {t("manage.deactivate")}
            </Title>
          </Desktop>
        </FlexWrapper>
        <div style={{ flex: 1, width: "100%" }}>
          {accounts.map((account: Account) => (
            <AccountsItem key={account.Id}>
              <FlexWrapper>
                <span>
                  <Img width={30} src="/home/greece-wallet.svg" alt="wallet" />
                </span>
                <div>
                  <Title ml="0.875rem" mb="0" size="14px" weight={500}>
                    {account?.CustomAccountName}
                  </Title>
                  <Text ml="0.875rem" size="0.75rem" color="#949494">
                    <Balance currency={account?.CurrencyName} />
                    <strong
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        paddingLeft: 4,
                        color: "#212121",
                      }}
                    >
                      {getCurrencySymbol(account?.CurrencyName)}
                    </strong>

                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: 4,
                        transform: "translateY(3px)",
                      }}
                      onClick={() => {
                        setTempAccount(account);
                        setIsFavouriteOpen(true);
                      }}
                    >
                      <Star
                        fill={
                          favouriteCurrency === account.Id
                            ? "#FFBD23"
                            : "#E6E6E6"
                        }
                      />
                    </div>
                  </Text>
                </div>
              </FlexWrapper>
              <div
                className="edit"
                onClick={() => {
                  setTempAccount(account);
                  setRenameModal(true);
                }}
              >
                <Img src="/home/edit.svg" alt="edit" />
              </div>
            </AccountsItem>
          ))}
        </div>
        <TabletOrMobile>
          <Title
            mt="1.25rem"
            className="pointer"
            color="#E02629"
            size="12px"
            onClick={() => setIsDeactivateOpen(true)}
          >
            {t("manage.deactivate")}
          </Title>
        </TabletOrMobile>
      </div>

      <FavouriteModal
        // @ts-ignore
        tempAccount={tempAccount}
        isOpen={isFavouriteOpen}
        onClose={() => setIsFavouriteOpen(false)}
        onSet={setFavouriteAccountHandler}
      />
      <DeactivationModal
        isOpen={deactivateOpen}
        onClose={() => setIsDeactivateOpen(false)}
        onInitiate={() => {
          setIsDeactivateOpen(false);
          setIsPasswordOpen(true);
        }}
      />

      <NonDeactivationModal
        isOpen={nonDeactivationModal}
        onClose={() => {
          setNonDeactivationModal(false);
          navigate("/home");
        }}
      />
      <PasswordModal
        isOpen={passwordOpen}
        onClose={() => setIsPasswordOpen(false)}
        onInitiate={(password: string) => {
          handleDeactivationInitiation(password);
        }}
      />
      <RenameModal
        initialName={tempAccount?.CustomAccountName}
        isOpen={renameModal}
        onClose={() => setRenameModal(false)}
        onInitiate={(newName: string) => {
          handleRename(newName);
        }}
      />

      <TwoFactorModal
        displayCode={user?.clientPhone}
        isOpen={isTwofactorOpen}
        onClose={() => setIsTowfactorOpen(false)}
        onConfirm={(pin: any) => handleDeactivationConfirm(pin)}
      />
    </AccountsWrapper>
  );
};

export default Accounts;
