import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ContentWrapper } from "./Bankstansfer.styled";
import Details from "./Parts/Details";
import FinancialDetails from "./Parts/FinancialDetails";
import Review from "./Parts/Review";
import Destination from "./Parts/Destination";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import {
  ApproveBankTransfer,
  CreateBankTransferRequest,
  CreateBankTransferTemplate,
  CreateSEPATransfer,
  UploadSEPAFile,
  getImage,
  getConfiguration,
} from "../../services/Services";
import {
  bankTransferConfigDataSelector,
  bankTransferDataSelector,
  userIdSelector,
  userSelector,
} from "../../redux/selectors";
import TwoFactorModal from "../../components/TwoFactor/Index";
import {
  setBankTransferConfigData,
  setBankTransferData,
} from "../../redux/sepaSlice";
import ResultModal from "../../components/ResultModal";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import InterruptionModal from "../../components/InterruptionModal";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../utils/helpers";
import Header from "../../partials/Header";
import Text from "../../components/Text/text";
import { SaveasTempalateWrapper } from "../../components/ResultModal/ResultModal.styled";
import AddIcon from "@mui/icons-material/Add";
import ProgressLines from "../../components/ProgressLines/ProgressLines";
import { useToast } from "../../hooks/useToast";

const BankTransfer = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const userId = useSelector(userIdSelector);
  const bankTransferData = useSelector(bankTransferDataSelector);
  const bankTransferConfigData = useSelector(bankTransferConfigDataSelector);
  const isSEPA = bankTransferConfigData?.SupportsSepa;
  const [transferId, setTransferId] = useState(0);
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const dispatch = useDispatch();
  const [resultModalOpen, setIsResultModalOpen] = useState(false);
  const [templateSuccess, setTemplateSuccess] = useState(false);
  const { addToast } = useToasts();
  const [resultMode, setResultMode] = useState("");
  const transactionType =
    bankTransferData?.transactionType ||
    (bankTransferConfigData?.TransactionTypes?.length &&
      bankTransferConfigData?.TransactionTypes[0]);
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const navigate = useNavigate();
  const { state: chosenTemplate } = useLocation();
  const user = useSelector(userSelector);
  const prevActiveTab = useRef(0);
  const [animationClass, setAnimationClass] = useState("");
  const [countrySvg, setCountrySvg] = useState(null);
  const [blsError, setBlsError] = useState(false);
  const toast = useToast();


  useEffect(() => {
    if (chosenTemplate) {
      setTemplate(chosenTemplate);
      setProgress(3);
    }
  }, [chosenTemplate]);

  useEffect(() => {
    return () => {
      dispatch(setBankTransferData({ reset: true }));
    };
  }, []);

  const handleBankTransferConfirm = () => {
    if (isSEPA) {
      CreateSEPATransfer(
        userId,
        bankTransferData.accountId,
        bankTransferData.firstName,
        bankTransferData.lastName,
        bankTransferData.receiverCurrencyCode,
        bankTransferData.countryId || bankTransferData.chosenCountry?.countryId,
        bankTransferData.firstName + " " + bankTransferData.lastName,
        bankTransferData.bank || bankTransferData.bankName,
        bankTransferData.bic,
        bankTransferData.iban,
        bankTransferData.entityType,
        bankTransferData.saveAsTemplate || false,
        bankTransferData.info,
        parseFloat(bankTransferData.debitAmount),
        parseFloat(bankTransferData.creditAmount),
        bankTransferData.exchangeRate
      )
        .then((res: any) => {
          if (res.status === 202) {
            setBlsError(true);
            setIsResultModalOpen(true);
            setResultMode("fail");
          } else {
            setIsTwofactorOpen(true);
            setTransferId(res.data.Content.TransferId);
          }
        })
        .catch((err) => {
          if (err.response.status === 406) {
            setBlsError(true);
            setIsResultModalOpen(true);
            setResultMode("fail");
          } else if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else {
      CreateBankTransferRequest(
        bankTransferData.accountId,
        userId,
        bankTransferData.creditAmount,
        bankTransferData.debitAmount,
        bankTransferData.exchangeRate,
        bankTransferData.creditCurrency,
        true,
        bankTransferData.firstName,
        bankTransferData.lastName,
        bankTransferData.chosenCountry?.countryId ||
          bankTransferData?.countryId ||
          bankTransferData.chosenCountry?.CountryId,
        bankTransferData.phoneNumber,
        bankTransferData.chosenCountry?.CountryCode ||
          bankTransferData.chosenCountry?.countryCode ||
          bankTransferData.CountryCode,
        bankTransferData.chosenCountry?.country || bankTransferData.Country,
        bankTransferData.officeCode,
        bankTransferData.accountNumber || bankTransferData.iban,
        bankTransferData.info,
        transactionType,
        bankTransferData.parameters,
        bankTransferData?.entityType,
      )
        .then((res: any) => {
          if (res.status === 202) {
            setBlsError(true);
            setIsResultModalOpen(true);
            setResultMode("fail");
          } else {
            setIsTwofactorOpen(true);
            setTransferId(res.data.Content.TransferId);
          }
        })
        .catch((err) => {
          // setIsResultModalOpen(true)

          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  };

  const handleBankApprove = (pin?: any) => {
    if (pin?.length === 6) {
      ApproveBankTransfer(userId, transferId, pin, isSEPA)
        .then((res) => {
          if (bankTransferData.file) {
            UploadSEPAFile(userId, transferId, bankTransferData.file)
              .then(() => {
                addToast(t("SEPA.filesuccess"), {
                  appearance: "success",
                  autoDismiss: true,
                });
              })
              .catch((err) => {
                addToast("Something went wrong", {
                  appearance: "error",
                  autoDismiss: true,
                });
              });
          }
          setIsTwofactorOpen(false);
          setResultMode("success");
          setIsResultModalOpen(true);
          setActiveTab(0);
          setProgress(0);
        })
        .catch((err) => {
          setResultMode("fail");
          if (err.response.data.errors) {
            Object.values(err.response.data.errors).forEach(
              (errorArray: any) => {
                errorArray.forEach((item: any) => {
                  addToast(<div>{item}</div>, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                });
              }
            );
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else if (pin?.length > 0 && pin?.length < 6){
      toast(t("errorboundary.smspin"), "error");
    }
  };

  const addTemplate = () => {
    CreateBankTransferTemplate(
      bankTransferData.firstName + " " + bankTransferData.lastName,
      bankTransferData.CountryId || bankTransferData.countryId,
      userId,
      bankTransferData.bank || bankTransferData.bankName,
      bankTransferData.bic,
      bankTransferData.accountNumber || bankTransferData.iban,
      bankTransferData.info,
      bankTransferData.additionalCode,
      bankTransferData.transactionType,
      bankTransferData.lastName,
      bankTransferData.firstName,
      bankTransferData.countryId,
      bankTransferData.phoneNumber,
      bankTransferData.entityType,
      bankTransferData.officeCode ?? 0
    )
      .then((res) => {
        setTemplateSuccess(true);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    if (
      bankTransferData?.CountryCode ||
      bankTransferData?.Alpha2 ||
      bankTransferData?.alpha2
    ) {
      getImage(
        bankTransferData.CountryCode ||
          bankTransferData?.Alpha2 ||
          bankTransferData?.alpha2
      ).then((res) => setCountrySvg(res.data));
    }
  }, [bankTransferData]);

  const setTemplate = (item: any) => {
    dispatch(setBankTransferData({ reset: true }));
    setTimeout(() => {
      dispatch(
        setBankTransferData({
          firstName: item.Receiver.FirstName,
          lastName: item.Receiver.LastName,
          iban: item.AccountNumber,
          officeCode: item.OfficeDetail.OfficeCode,
          bic: item.BIC,
          bankName: item.BankName,
          CountryCode: item.Alpha2,
          Country: item.Country,
          countryId: item.CountryCodeId,
          entityType: item.EntityType,
          parameters: item.Parameters,
          phoneNumber: item.PhoneNumber || item.Receiver.PhoneNumber,
          info: item.PaymentDetails,
          chosenBank: item.OfficeDetail,
          isFromActiveTemplate: true,
          isSepa: item.IsSepa,
        })
      );
      getConfiguration(item.CountryCodeId).then((res) => {
        dispatch(setBankTransferConfigData(res.data.Content));
      });
      setActiveTab(2);
      setProgress(2);
    }, 200);
  };

  const handleGoBack = () => {
    if (isEmpty(bankTransferData)) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  useEffect(() => {
    if (activeTab > prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-right duration-500");
    } else if (activeTab < prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-left duration-500");
    } else {
      setAnimationClass("");
    }
    prevActiveTab.current = activeTab;
  }, [activeTab]);

  const additionalContent = () => {
    return resultMode === "success" ? (
      <SaveasTempalateWrapper>
        <div
          // @ts-ignore
          dangerouslySetInnerHTML={{ __html: countrySvg }}
        />
        <Text color="#171922" ta="center" size="16px" mb="0.2rem">
          {bankTransferData?.firstName} {bankTransferData?.lastName}
        </Text>
        <Text color="#171922" mb="0.25rem">
          {bankTransferData?.creditAmount}{" "}
          {bankTransferData?.creditCurrency ||
            bankTransferData?.recipientCurrency}
        </Text>

        {!templateSuccess && !bankTransferData.isFromActiveTemplate && (
          <button onClick={addTemplate} className="text-xs pt-2">
            <AddIcon /> {t("banktransfer.result.saveastemplate")}
          </button>
        )}
      </SaveasTempalateWrapper>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Header onLogoClick={handleGoBack} />
      <ContentWrapper>
        <Tabs selectedIndex={activeTab}>
          <FlexWrapper align="center" justify="between" mb="1.25rem">
            <TabletOrDesktop>
              <div onClick={handleGoBack}>
                <Img className="logo" src={"/logo.svg"} alt="logo" />
              </div>

              <ProgressLines
                maxWidth="980px"
                activeTab={activeTab}
                tabs={[
                  {
                    title: "banktransfer.tab1",
                  },
                  {
                    title: "banktransfer.tab2",
                  },
                  {
                    title: "banktransfer.tab3",
                  },
                  {
                    title: "banktransfer.tab4",
                  },
                ]}
                progress={progress}
                setActiveTab={(index: number) => setActiveTab(index)}
              />
              <div className="close" onClick={handleGoBack}>
                <CloseRoundedIcon />
              </div>
            </TabletOrDesktop>
          </FlexWrapper>

          <TabPanel
            key={`tab-panel-0-${activeTab}`}
            className={`animate-fadeIn`}
          >
            <Destination
              handleGoback={handleGoBack}
              onNextPress={() => {
                setProgress(1);
                setActiveTab(1);
              }}
              setTemplateSend={setTemplate}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-1-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <Details
              onPreviousPress={() => setActiveTab(0)}
              onNextPress={() => {
                setProgress(2);
                setActiveTab(2);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-2-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <FinancialDetails
              onPreviousPress={() => setActiveTab(1)}
              onNextPress={() => {
                setProgress(3);
                setActiveTab(3);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-3-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <Review
              onConfirmPress={handleBankTransferConfirm}
              onPreviousPress={() => setActiveTab(2)}
            />
          </TabPanel>
        </Tabs>
        <TwoFactorModal
          displayCode={user?.clientPhone}
          isOpen={isTwofactorOpen}
          onResend={handleBankTransferConfirm}
          onClose={() => {
            setIsTwofactorOpen(false);
            setTransferId(0);
          }}
          onConfirm={(pin: any) => handleBankApprove(pin)}
        />
        <ResultModal
          mode={resultMode}
          transferId={transferId}
          isOpen={resultModalOpen}
          type="banktransfer"
          fileName="bank-transfer-document"
          showPdfDownloadButton={true}
          additionalContent={additionalContent}
          onClose={() => {
            dispatch(setBankTransferData({ reset: true }));
            setIsResultModalOpen(false);
            navigate("/home");
          }}
          imageUrl={blsError ? "/transactions/not-completed.gif" : ""}
          failTitle={blsError ? t("transfers.cantcomplete") : ""}
          failText={blsError ? t("transfers.cantcompletetext") : ""}
          failButtons={[
            {
              name: "common.backtohome",
              className: "btn btn-red uppercase h-[38px]",
              buttonVariant: "back",
              onClick: () => {
                dispatch(setBankTransferData({ reset: true }));
                setIsResultModalOpen(false);
                navigate("/home");
              },
            },
            {
              name: "contact.title",
              className: "btn btn-plain uppercase h-[38px]",
              buttonVariant: "back",
              onClick: () => {
                navigate("/contact");
              },
            },
          ]}
        />
        <InterruptionModal
          title={`${t("common.sending")} ${t("common.form")}`}
          text={t("common.interruption")}
          isOpen={isInterruptionOpen}
          onStayClick={() => setIsInterruptionOpen(false)}
          onCloseClick={() => {
            dispatch(setBankTransferData({ reset: true }));
            navigate("/home");
            setIsInterruptionOpen(false);
          }}
        />
      </ContentWrapper>
    </>
  );
};

export default BankTransfer;
