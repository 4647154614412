import styled from "styled-components";
import { media } from "../../../utils/theme";

import Select from "../../../components/Form/Select";

export const Wrapper = styled.div`
  margin-top: 1.5rem;
  padding: 1rem 1rem 0 1rem;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 335px;
  width: 100%;
  background: #fff;

  ${media.size("xlarge")} {
    width: 310px;
  }

  ${media.lessThan("tablet")} {
    max-width: 100%;
    margin-top: 0rem;
  }

  ${media.lessThan("small")} {
    max-width: 100%;
  }

  ${media.lessThan("small")} {
    // max-width: calc(100% - 16px);
    // margin-top: 0rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 1300px) and (min-width: 1024px) {
    width: 100%;
  }
`;

export const TopSection = styled.div<{ lang?: string }>`
  display: flex;
  align-items: center;
  padding: 0rem;
  flex: 0.25;

  div.buy,
  div.sell {
    width: 43px;
    margin: 0 0 0 1rem;
    text-align: right;
    color: #cdcdcd !important;
    font-size: 12px;
  }

  div.sell {
    margin-left: 27px;
  }

  ${(p) =>
    p.lang === "ka" &&
    `
   
    div.sell {
      margin-right: 0.75rem;
      margin-left: 14px;
    }
  `}

  ${(p) =>
    p.lang === "ru" &&
    `
    div.sell {
    margin-right: 1rem;
    margin-left: 7px;
    }
  `}
`;

export const RateItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .currency-name {
    display: flex;
    align-items: center;
    flex: 0.9;
    flex-shrink: 0;

    svg {
      width: 24px;
      height: 24px;
    }

    div {
      margin-bottom: 0;
    }
  }

  .rate {
    margin-bottom: 0;
    flex: 0.35;
    text-align: right;
  }
`;

export const RatesSelect = styled(Select)`
  max-width: 186px;
  margin: 0.5rem 0.25rem;

  .custom-header {
    color: white;
    background: var(--text-color);
    padding: 0.58rem;
  }

  svg path {
    fill: white;
  }

  .dropdownItem {
    display: flex;
    align-items: center;

    img {
      margin-right: 1rem;
    }
  }

  .dropdownItem:hover {
    background: var(--pink);
    color: var(--text-color);
  }
`;

export const RatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 110px;
`;
