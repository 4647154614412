import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Scrollbars from "rc-scrollbars";

import { userIdSelector } from "../../../redux/selectors";
import {
  CashPickupTransferTopCountries,
  OtherCashPickupCountries,
  getImage,
} from "../../../services/Services";
import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import MultiRenderer from "../../../components/MultiRenderer";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";

const Wrapper = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  margin: 24px 0px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }
`;

const CountryItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #efefef;
  }

  svg {
    max-width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 18.75rem;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

  button {
    position: absolute;
    right: 2px;
    margin-top: 0 !important;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    outline: none;
    padding: 0 !important;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;

    img {
      width: 24px;
    }
  }
`;

type Props = {
  chooseCountry: (country: any) => void;
  openSepaInfoModal: (country: any) => void;
};

const CountrySearch = ({ chooseCountry, openSepaInfoModal }: Props) => {
  const { t } = useTranslation();
  const userId = useSelector(userIdSelector);
  const [countries, setCountries] = useState<any>([]);
  const [otherCountries, setOtherCountries] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<any>(countries);
  const [otherData, setOtherData] = useState<any>([]);
  const [svgImages, setSvgImages] = useState<any>([]);
  const [otherSvgImages, setOtherSvgImages] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      CashPickupTransferTopCountries(userId).then((res) => {
        setCountries(res.data.Content);
        setData(res.data.Content);
        setLoading(false);
      });

      OtherCashPickupCountries("CashPickup").then((res) => {
        setOtherData(res.data);
        setOtherCountries(res.data);
        setLoading(false);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (countries?.length) {
      var imageCodes: any = countries.map((item: any) => item.CountryCode);
      imageCodes.map((item: any) =>
        getImage(item === "GE" ? "gel" : item).then((res) => {
          setSvgImages((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (otherCountries?.length) {
      var imageCodes: any = otherCountries.map((item: any) => item.countryCode);
      imageCodes.map((item: any) =>
        getImage(item === "GE" ? "gel" : item).then((res) => {
          setOtherSvgImages((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        })
      );
    }
  }, [otherCountries]);

  useEffect(() => {
    setData(
      countries.filter((country: any) => {
        return country.Country.toLowerCase().includes(
          searchValue.toLowerCase()
        );
      })
    );
    setOtherData(
      otherCountries.filter((country: any) => {
        return country.country
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );
  }, [searchValue]);

  return (
    <Wrapper>
      <FlexWrapper align="center">
        <SearchWrapper className="search-wrapper">
          <span>
            <input
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("common.searchcountry")}
              onKeyPress={(event: any) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            />
          </span>

          <button className="shrink-0">
            <Img src={"/SEPA/search.svg"} alt="search icon" />
          </button>
        </SearchWrapper>
      </FlexWrapper>

      <div>
        <Scrollbars style={{ height: 430 }}>
          {!loading && !!data?.length && (
            <>
              <Title
                size="12px"
                ml="1rem"
                mt="0.5rem"
                weight="500"
                ls="0px"
                color="#000000"
              >
                {t("banktransfer.topdirections")}
              </Title>
              <Divider
                width="calc(100% - 32px)"
                mb="0.5rem"
                ml="1rem"
                mr="1rem"
              />
            </>
          )}
          {loading ? (
            <MultiRenderer quantity={5}>
              <TemplateSkeleton />
            </MultiRenderer>
          ) : (
            data.map((country: any) => (
              <CountryItem
                key={country.CountryCode}
                onClick={() => chooseCountry(country)}
              >
                <FlexWrapper align="center" mb="0">
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      width: 40,
                      height: 40,
                      textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: svgImages[country.CountryCode],
                    }}
                  />
                  <div>
                    <Text ml="8px" size="14px" color="#171922" mb="0.2rem">
                      {" "}
                      {country.Country}
                    </Text>
                    {country.IsCentralized && (
                      <Text color="#56575B" ml="8px">
                        {t("cashpickup.centralizedsystem")}
                      </Text>
                    )}
                  </div>
                </FlexWrapper>
                {country.IsCentralized && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      openSepaInfoModal(country);
                    }}
                  >
                    <Img src="/transfers/state-layer.svg" alt="sepa" />
                  </span>
                )}
              </CountryItem>
            ))
          )}
          {!loading && !!otherData.length && (
            <>
              <Title
                size="12px"
                ml="1rem"
                mt="1rem"
                weight="500"
                ls="0px"
                color="#000000"
              >
                {t("banktransfer.othercountries")}
              </Title>
              <Divider
                width="calc(100% - 32px)"
                mb="0.5rem"
                ml="1rem"
                mr="1rem"
              />
            </>
          )}
          {!loading &&
            otherData.map((country: any) => (
              <CountryItem
                key={country.CountryCode}
                onClick={() => chooseCountry(country)}
              >
                <FlexWrapper align="center" mb="0">
                  <div style={{ width: 40 }}>
                    <div
                      style={{
                        borderRadius: "50%",
                        overflow: "hidden",
                        width: 40,
                        height: 40,
                        textAlign: "center",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: otherSvgImages[country.countryCode],
                      }}
                    />
                  </div>
                  <div>
                    <Text ml="8px" size="14px" color="#171922" mb="0.2rem">
                      {" "}
                      {country.country}
                    </Text>
                    <Text color="#56575B" ml="8px">
                      {country.isCentralized
                        ? t("cashpickup.centralizedsystem")
                        : t("cashpickup.notcentralizedsystem")}
                    </Text>
                  </div>
                </FlexWrapper>
                {country.isCentralized && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      openSepaInfoModal(country);
                    }}
                  >
                    <Img src="/transfers/state-layer.svg" alt="sepa" />
                  </span>
                )}
              </CountryItem>
            ))}
          {!data.length && !otherData.length && !loading && (
            <div
              className="flex justify-center font-semibold"
              style={{ padding: "60px 20px 60px" }}
            >
              {t("banktransfer.nothingfound")}
            </div>
          )}
        </Scrollbars>
      </div>
    </Wrapper>
  );
};

export default CountrySearch;
