import { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigate, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Header from "../partials/Header";
import { media } from "../utils/theme";
import { TabletOrMobile } from "../components/responsive/responsive";
import { getStorageObject, getToken } from "../utils/storage";
import { globalLoadingSelector } from "../redux/selectors";
import Img from "../components/Img";
import { LogoutService } from "../services/Services";
import SessionModal from "../components/SessionModal";
import RetryPromisesProvider from "../RetryPromisesProviderts";
import { LoadingOverlay } from ".";
import { MainContent } from "./MainLayout";
import { MetaData } from "../components/MetaData";
import { setLoading } from "../redux/commonSlice";

const PlaceHolder = styled.div`
  flex: 1;

  ${media.size("xlarge")},
  ${media.size("large")} {
    padding: 1rem;
  }

  ${media.lessThan("tablet")} {
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  ${media.lessThan("small")} {
    padding: 0;
  }
`;

const HIDE_HEADER_URLS = [
  "/banktransfer",
  "/cashpickup",
  "/contact",
  "/about",
  "/privacy-conditions",
];

const PlainLayoutWrapper = ({ children }: any) => {
  const isLoading = useSelector(globalLoadingSelector);
  const location = useLocation();
  const hideHeader = HIDE_HEADER_URLS.includes(location.pathname);
  const hidePhArray = ["/privacy-conditions", "/contact", "/about"];
  const hidePh = hidePhArray.includes(location.pathname);
  const dispatch = useDispatch();
  const token = getToken();
  const [state, setState] = useState<string>("Active");
  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    if (state !== "Idle") {
      setState("Active");
    }
  };

  const { reset } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 120000,
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        reset();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [reset]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("dateOfClose", JSON.stringify(new Date()));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const timestamp1 = moment(getStorageObject("dateOfClose"));
    const timestamp2 = moment(new Date());

    const differenceInMinutes = timestamp2.diff(timestamp1, "minutes");

    if (differenceInMinutes >= 3 && token) {
      LogoutService().then(() => {});
      localStorage.removeItem("token");
      localStorage.removeItem("dateOfClose");
      localStorage.removeItem("user");
      window.location.href = "/login";
    } else {
      localStorage.removeItem("dateOfClose");
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(setLoading(false));
  }, [location.pathname]);

  return (
    <div style={{ background: "#fbfbfb", minHeight: "auto" }}>
      <MetaData />
      {!hideHeader && (
        <TabletOrMobile>
          <Header />
        </TabletOrMobile>
      )}

      <MainContent display="block" hidePh={hidePh}>
        {hidePh ? (
          <div className="flex-1">{children}</div>
        ) : (
          <PlaceHolder>
            <RetryPromisesProvider>{children}</RetryPromisesProvider>
          </PlaceHolder>
        )}
      </MainContent>
      {isLoading && (
        <LoadingOverlay>
          <Img src="/transfers/inex-logo.png" alt="logo" />
        </LoadingOverlay>
      )}
      {token && (
        <SessionModal
          isOpen={state === "Idle"}
          onClose={() => setState("Active")}
          onContinue={() => {}}
        />
      )}
    </div>
  );
};

const PlainLayout = ({ component: Component, noauth }: any) => {
  if (!noauth) {
    return getToken() ? (
      <PlainLayoutWrapper>
        <Component />
      </PlainLayoutWrapper>
    ) : (
      <Navigate to="/login" />
    );
  } else {
    return (
      <PlainLayoutWrapper>
        <Component />
      </PlainLayoutWrapper>
    );
  }
};

export default PlainLayout;
