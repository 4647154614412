import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "styled-components";

import FlexWrapper from "../../../components/FlexWrapper";
import Img from "../../../components/Img";
import { UnderReview } from "./UnderReview";
import { AccountApproved } from "./AccountApproved";
import { AccountNotApproved } from "./AccountNotApproved";
import { checkBLSStatus } from "../../../services/Services";
import { useToast } from "../../../hooks/useToast";

const Wrapper = styled.div`
  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;
    flex-shrink: 0;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const BLS = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.rejected) {
      setStep(3);
    } else if (step === 1 && location?.state?.token) {
      const sendRequest = () => {
        checkBLSStatus(location?.state?.token)
          .then((res) => {
            if (res.status === 200) {
              setStep(2);
            } else if (res.status === 202) {
              setStep(1);
            }
          })
          .catch((err) => {
            if (err.response.status === 406) {
              setStep(3);
            } else {
              toast(err.response.data.StatusMessage, "error");
              navigate("/login");
            }
          });
      };
      sendRequest();

      const intervalId = setInterval(sendRequest, 3 * 60 * 1000);

      return () => clearInterval(intervalId);
    } else if (!location?.state?.token) {
      navigate("/login");
    }
  }, [step]);

  return (
    <Wrapper>
      <div className="max-w-[1240px] mx-auto pb-12">
        <FlexWrapper justify="space-between" className="md:px-0 px-4 py-12">
          <div>
            <Link to="/login">
              <Img width={85} src={"/logo.svg"} alt="logo" />
            </Link>
          </div>

          <div className="close">
            <Link to="/home">
              <CloseRoundedIcon />
            </Link>
          </div>
        </FlexWrapper>

        {step === 1 && <UnderReview onNextClick={() => navigate("/login")} />}
        {step === 2 && (
          <AccountApproved onNextClick={() => navigate("/login")} />
        )}
        {step === 3 && (
          <AccountNotApproved onNextClick={() => navigate("/login")} />
        )}
      </div>
    </Wrapper>
  );
};

export default BLS;
