import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Img from "../components/Img";
import { getToken, getUserId } from "../utils/storage";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { profilePhotoSelector, userSelector } from "../redux/selectors";
import { setProfilePhoto } from "../redux/authSlice";
import { baseURL } from "../services/axios";
import { Mobile, TabletOrMobile } from "../components/responsive/responsive";

const ContentHeader = ({ onLogoClick }: any) => {
  const token = getToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { t, i18n } = useTranslation();
  const languageKey = i18n.language;
  const profilePhoto = useSelector(profilePhotoSelector);
  const [source, setSource] = useState("");
  const userId = getUserId();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userFullName = user.fullName?.split("");
  const nameInitial = userFullName && userFullName[0].split("")[0];
  const lastNameInitial = userFullName && userFullName[1].split("")[0];

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850 && open) {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);

  async function fetchImageStream() {
    const response = await fetch(
      `${baseURL}profile/profilePicture?userid=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "image/jpeg",
          Authorization: getToken(),
        },
      }
    );

    if (response.status === 200) {
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSource(imageUrl);
      dispatch(setProfilePhoto(imageUrl));
    } else {
      dispatch(setProfilePhoto(""));
    }
  }

  useEffect(() => {
    if (!profilePhoto) {
      fetchImageStream();
    }
  }, []);

  useEffect(() => {
    if (!profilePhoto) {
      setSource("");
    }
  }, [profilePhoto]);

  const Menu = (
    <div className=" h-full ">
      <div className="w-[270px] p-4 flex justify-between flex-col h-full bg-white float-right">
        <div>
          <div
            className="absolute right-2 top-4 cursor-pointer"
            onClick={toggleDrawer(false)}
          >
            <Img
              src="/auth/close.svg"
              alt="close menu"
              className="p-2 rounded-full bg-[#F0F0F0]"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={toggleDrawer(false)}
              className="absolute left-[-12px] top-1/2 "
            >
              <Img
                src="/Arrowleft.svg"
                alt="arrow left"
                className="  p-[5.64px] bg-white rounded-md shadow border border-gray-300 border-solid"
              />
            </button>
          </div>
          <div className="text-[#56575B] font-semibold flex sm-gap-8 gap-4 flex-col py-6 pr-12">
            <Link
              to="/home"
              className={
                location.pathname === "/home"
                  ? "text-black font-bold"
                  : "hover:opacity-80"
              }
            >
              {t("header.home")}
            </Link>
            <Link
              to="/about"
              className={
                location.pathname === "/about"
                  ? "text-black font-bold"
                  : "hover:opacity-80"
              }
            >
              {t("header.aboutus")}
            </Link>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact"
                  ? "text-black font-bold"
                  : "hover:opacity-80"
              }
            >
              {t("header.contactsupport")}
            </Link>
            <Link
              to="/privacy-conditions"
              className={
                location.pathname === "/privacy-conditions"
                  ? "text-black font-bold"
                  : "hover:opacity-80"
              }
            >
              {t("header.privacy")}
            </Link>
          </div>
        </div>
        <div>
          {!token && (
            <div className="flex gap-4 [@media(min-width:850px)]:flex-row flex-col w-full [@media(min-width:850px)]:w-auto py-10">
              <Link
                to="/login"
                className="rounded-lg  flex justify-center items-center px-[16px] py-[10px] hover:opacity-80"
              >
                <p className="text-black !text-[16px] font-semibold text-center ">
                  {t("header.login")}
                </p>
              </Link>

              <Link
                to="/registration"
                className="bg-rose-500 rounded-lg  flex justify-center items-center px-[16px] py-[10px] hover:bg-rose-400"
              >
                <p className="text-white !text-[16px] font-semibold text-center ">
                  {t("header.signup")}
                </p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`bg-[#212121] ${fontClass} !sticky !top-[-1px]`}>
      <div className="max-w-[1440px] mx-auto ">
        <div className="mx-auto px-4 lg:px-10 sm:px-8 relative">
          <div className="flex gap-10 py-[28px] h-20 justify-between [@media(min-width:850px)]:justify-start items-center">
            <div
              onClick={() => (onLogoClick ? onLogoClick() : navigate("/home"))}
              className="cursor-pointer shrink-0 flex items-center justify-center"
            >
              <Img src="/auth/Logo.svg" alt="logo" className="block" />
            </div>
            <TabletOrMobile>
              {token ? (
                <Link to="/profile" style={{ width: 40, height: 40 }}>
                  {profilePhoto || source ? (
                    <div className="w-10 h-10 rounded-full overflow-hidden">
                      <img
                        style={{ objectFit: "cover" }}
                        src={profilePhoto || source}
                        alt="profile"
                        className="h-full w-full "
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="w-10 h-10 rounded-full bg-[#F2F4F7] flex justify-center items-center">
                        <span className="text-[#667085] uppercase font-semibold">
                          {nameInitial}
                          {lastNameInitial}
                        </span>
                      </div>
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  onClick={toggleDrawer(true)}
                  className="visible [@media(min-width:850px)]:hidden cursor-pointer z-50 block"
                >
                  <Img src="/auth/menu.svg" alt="burger menu" />
                </div>
              )}
            </TabletOrMobile>
            <div className=" justify-between items-center w-full [@media(min-width:850px)]:flex hidden">
              <div className="text-[#94969C] font-semibold [@media(min-width:850px)]:flex hidden gap-6 flex-row">
                <Link
                  to="/home"
                  className={
                    location.pathname === "/home"
                      ? "text-stone-300 font-bold"
                      : "hover:opacity-80"
                  }
                >
                  {t("header.home")}
                </Link>
                <Link
                  to="/about"
                  className={
                    location.pathname === "/about"
                      ? "text-stone-300 font-bold"
                      : "hover:opacity-80"
                  }
                >
                  {t("header.aboutus")}
                </Link>
                <Link
                  to="/contact"
                  className={
                    location.pathname === "/contact"
                      ? "text-stone-300 font-bold"
                      : "hover:opacity-80"
                  }
                >
                  {t("header.contactsupport")}
                </Link>
                <Link
                  to="/privacy-conditions"
                  className={
                    location.pathname === "/privacy-conditions"
                      ? "text-stone-300 font-bold"
                      : "hover:opacity-80"
                  }
                >
                  {t("header.privacy")}
                </Link>
              </div>
              {!token && (
                <div className="hidden [@media(min-width:850px)]:flex gap-4 ">
                  <Link
                    to="/login"
                    className="rounded-lg  flex justify-center items-center px-[16px] py-[10px] hover:opacity-80"
                  >
                    <p className="text-white !text-[16px] font-semibold text-center ">
                      {t("header.login")}
                    </p>
                  </Link>
                  <Link
                    to="/registration"
                    className="bg-rose-500 rounded-lg  flex justify-center items-center px-[18px] py-[10px] hover:bg-rose-400"
                  >
                    <p className="text-white !text-[16px] font-semibold text-center ">
                      {t("header.signup")}
                    </p>
                  </Link>
                </div>
              )}
            </div>

            <Drawer
              sx={{
                "& .MuiPaper-root": {
                  overflowY: "visible",
                },
              }}
              BackdropProps={{
                style: {
                  background: "rgba(49, 48, 90, 0.2)",
                  backdropFilter: "blur(7px)",
                },
              }}
              anchor="right"
              open={open}
              onClose={toggleDrawer(false)}
            >
              {Menu}
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
