import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useLocation } from "react-router-dom";
import Scrollbars from "rc-scrollbars";

import {
  setBankTransferConfigData,
  setBankTransferData,
} from "../../../redux/sepaSlice";
import {
  CountrySelector,
  DestinationForm,
  Dot,
  TemplateItemWrapper,
  Templates,
} from "./Destination.styled";
import FlexWrapper from "../../../components/FlexWrapper";
import BackButton from "../components/BackButton";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import Divider from "../../../components/Divider";
import CountrySearch from "../components/CountrySearch";
import {
  GetBankTransferTemplates,
  getConfiguration,
  getImage,
} from "../../../services/Services";
import SepaInfoModal from "../components/SepaInfoModal";
import {
  bankTransferDataSelector,
  langSelector,
} from "../../../redux/selectors";
import { isEmpty } from "../../../utils/helpers";
import Flag from "../../../components/Flag";
import TemplateSkeleton from "../../../components/Skeletons/TemplateSkeleton";
import MultiRenderer from "../../../components/MultiRenderer";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
  setTemplateSend: (item: any) => void;
  handleGoback: () => void;
}

const TemplateItem = ({ item, onClick }: any) => {
  return (
    <div className="d-flex align-items-center" onClick={onClick}>
      <TemplateItemWrapper>
        <div className="circle relative">
          {item?.Receiver?.FirstName?.substr(0, 1)}
          {item?.Receiver?.LastName?.substr(0, 1)}
          <Flag
            size={12}
            className="absolute right-[0px] bottom-[0px]"
            code={item?.Alpha2}
          />
        </div>
        <div style={{ flex: 1 }} className="!line-clamp-2 text-wrap">
          <Title mb="0.2rem" className="text-ellipsis overflow-hidden">
            {item?.TemplateName}
          </Title>
          <Text>
            <p>{item?.AccountNumber && `${item?.AccountNumber}`}</p>
          </Text>
        </div>
        <ArrowForwardIosOutlinedIcon />
      </TemplateItemWrapper>
    </div>
  );
};

const Destination = ({
  disabled,
  onNextPress,
  setTemplateSend,
  handleGoback,
}: DetailsProps) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const bankTransferData = useSelector(bankTransferDataSelector);
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [chosenCountry, setChosenCountry] = useState<any>(null);
  const [countrySvg, setCountrySvg] = useState(null);
  const [sepaInfoModal, setSepaInfoModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const location = useLocation();
  const isFromActiveTemplate = bankTransferData?.isFromActiveTemplate;
  const lang = useSelector(langSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname !== "/banktransfer") {
      setChosenCountry(null);
      dispatch(setBankTransferData({ reset: true }));
    }
  }, []);

  useEffect(() => {
    if (bankTransferData.chosenCountry) {
      setChosenCountry(bankTransferData.chosenCountry);
    }

    if (isEmpty(bankTransferData)) {
      setChosenCountry(null);
      setCountrySvg(null);
      GetBankTransferTemplates()
        .then((res) => {
          setLoading(false);
          setTemplates(res?.data?.Content);
        })
        .catch((err) => setLoading(false));
    }
  }, [bankTransferData]);

  useEffect(() => {
    GetBankTransferTemplates()
      .then((res) => {
        setLoading(false);
        setTemplates(res?.data?.Content);
      })
      .catch((e) => setLoading(false));
  }, []);

  useEffect(() => {
    if (chosenCountry) {
      getConfiguration(
        chosenCountry?.CountryId || chosenCountry?.countryId
      ).then((res) => {
        dispatch(setBankTransferData(chosenCountry));
        dispatch(setBankTransferConfigData(res.data.Content));
      });

      getImage(chosenCountry?.CountryCode || chosenCountry?.countryCode).then(
        (res) => setCountrySvg(res.data)
      );
    }
  }, [chosenCountry]);

  const onSubmit = (data?: any) => {
    const detailsData: any = {};

    detailsData.countryId = data.countryId || chosenCountry?.CountryId;
    detailsData.chosenCountry = chosenCountry;

    dispatch(setBankTransferData(detailsData));
    onNextPress && onNextPress();
  };

  return (
    <DestinationForm onSubmit={handleSubmit(onSubmit)} disabled={disabled}>
      <BackButton
        onClick={() =>
          showCountrySearch ? setShowCountrySearch(false) : handleGoback()
        }
      />

      <Title
        mb="0.5rem"
        ta="center"
        className="title"
        ls="-1px"
        size="2rem"
        weight="600"
      >
        {t("banktransfer.destination.title")}
      </Title>

      <Text ta="center" size="14px" color="#475467">
        {t("banktransfer.destination.subtitle")}
      </Text>
      {showCountrySearch ? (
        <div>
          <CountrySearch
            openSepaInfoModal={(country: any) => {
              setChosenCountry(country);
              dispatch(
                setBankTransferData({
                  chosenCountry: null,
                  chosenBank: null,
                })
              );
              setSepaInfoModal(true);
            }}
            chooseCountry={(country: any) => {
              setChosenCountry(country);
              dispatch(
                setBankTransferData({
                  chosenCountry: null,
                  chosenBank: null,
                })
              );
              setShowCountrySearch(false);
            }}
          />
        </div>
      ) : (
        <>
          <div>
            <CountrySelector
              disabled={isFromActiveTemplate}
              onClick={() => setShowCountrySearch(true)}
            >
              <Text
                size={lang === "ka" ? "14px" : "1rem"}
                color="#101828"
                weight="500"
              >
                {!chosenCountry?.Country && !chosenCountry?.country && (
                  <Dot></Dot>
                )}{" "}
                {(chosenCountry?.Country || chosenCountry?.country) ??
                  t("banktransfer.receivingcountry")}
              </Text>{" "}
              <FlexWrapper mb="0">
                <div
                  className="flag"
                  style={{
                    marginRight: 8,
                    width: 24,
                    height: 24,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                  // @ts-ignore
                  dangerouslySetInnerHTML={{ __html: countrySvg }}
                />
                {chosenCountry?.Country?.substr(0, 3) ||
                  chosenCountry?.country?.substr(0, 3)}
                <ArrowForwardIosOutlinedIcon
                  style={{ marginLeft: 20, height: 16 }}
                />
              </FlexWrapper>
            </CountrySelector>
          </div>

          {(chosenCountry?.Country || chosenCountry?.country) && (
            <FlexWrapper justify="center" mt="0.5rem">
              <button
                onClick={onSubmit}
                className="btn btn-red uppercase max-h-10"
                style={{ width: "320px", maxWidth: "100%" }}
              >
                {t("common.continue")}
              </button>
            </FlexWrapper>
          )}
          {!(
            chosenCountry?.Country ||
            chosenCountry?.country ||
            isFromActiveTemplate
          ) && (
            <Templates>
              <Title
                ml="1rem"
                className="uppercase"
                weight="600"
                ls="0px"
                size="12px"
                mb="0.25rem"
              >
                {t("banktransfer.mytemplates")}
              </Title>
              <Divider mt="0rem" mb="1rem" width="100%" />
              {loading ? (
                <div className="-mb-6">
                  <MultiRenderer quantity={3}>
                    <div className="mb-6">
                      <TemplateSkeleton />
                    </div>
                  </MultiRenderer>
                </div>
              ) : templates?.length ? (
                <Scrollbars style={{ height: 250, overflowX: "hidden" }}>
                  {templates.map((item: any) => (
                    <TemplateItem
                      key={item.TemplateName}
                      onClick={() => setTemplateSend(item)}
                      item={item}
                    />
                  ))}
                </Scrollbars>
              ) : (
                <div>
                  <Title mt="28px" size="14px" ta="center">
                    {t("banktransfer.notemplates")}
                  </Title>
                  <Text
                    ml="1rem"
                    mr="1rem"
                    size="10px"
                    className="uppercase"
                    ta="center"
                    color="#56575B"
                  >
                    {t("banktransfer.templatetext")}
                  </Text>
                </div>
              )}
            </Templates>
          )}
        </>
      )}
      <SepaInfoModal
        onContinue={onSubmit}
        isOpen={sepaInfoModal}
        onClose={() => setSepaInfoModal(false)}
      />
    </DestinationForm>
  );
};

export default Destination;
