import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { langSelector } from "../../../redux/selectors";
import { GetRates, getImage } from "../../../services/Services";
import { setRates as setReduxRates } from "../../../redux/authSlice";
import {
  // RatesSelect,
  TopSection,
  Wrapper,
} from "./Rates.styled";
import RateItem, { CurrencyItem, CurrencyItems } from "./RateItem";
import RateSkeleton from "../../../components/Skeletons/RateSkeleton";
import MultiRenderer from "../../../components/MultiRenderer";
import Title from "../../../components/Tailwind/Title";

export type Rate = {
  DebitCurrency: string;
  CreditCurrency: string;
  BuyingRate: number;
  SellingRate: number;
};

const Rates = () => {
  const chosenCurrency = "GEL";
  const filteredCurrencyRates = CurrencyItems.filter(
    (item: CurrencyItem) => item.DebitCurrency !== chosenCurrency
  );
  const [rates, setRates] = useState([]);
  const { t } = useTranslation();
  const lang = useSelector(langSelector);
  const dispatch = useDispatch();
  const [flags, setFlags] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (rates?.length) {
      var rateCurrencies: any = rates.map((item: Rate) => item.CreditCurrency);

      rateCurrencies.map((item: any) => {
        const type = item.split("/").pop();
        getImage(type).then((res) => {
          setFlags((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        });
      });
    }
  }, [rates]);

  useEffect(() => {
    GetRates()
      .then((res) => {
        setLoading(false);
        setRates(res.data.Content);
        dispatch(setReduxRates(res.data.Content));
      })
      .catch(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return (
      <Wrapper>
        <MultiRenderer quantity={3}>
          <RateSkeleton />
        </MultiRenderer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!rates.length ? (
        <MultiRenderer quantity={3}>
          <RateSkeleton />
        </MultiRenderer>
      ) : (
        <div>
          <Title variant="title1" className="color-[#171922] !font-medium mb-2 leading-4">
            {t("titles.exchange")}
          </Title>
          <TopSection lang={lang}>
            <div style={{ flex: 1 }}></div>
            <div className="buy text-sm capitalize">{t("common.buy")}</div>
            <div className="sell text-sm capitalize">{t("common.sell")}</div>
          </TopSection>
          {filteredCurrencyRates.map((item: CurrencyItem, index: number) => (
            <RateItem
              key={index}
              currency={item.DebitCurrency}
              chosenCurrency={chosenCurrency}
              flags={flags}
            />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Rates;
