import styled from "styled-components";
import { media } from "../../../utils/theme";

export const DetailsForm = styled.div<{ disabled?: boolean }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 575px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  .btn-red {
    margin: 20px auto;
    width: 300px;
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const ReceiverWrap = styled.div`
  background: #242424;
  padding: 4px 12px 4px 8px;
  color: white;
  border-radius: 4px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const BlockWrapper = styled.div`
  padding: 1rem 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 0.5rem;
  background: #fff;

  .select {
    width: 120px;
    height: 56px;
  }

  .body {
    width: 130px;
    margin-top: 4px;
  }

  .dd-header {
    border: 1px solid #cbcbcb;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    margin-bottom: 8px;

    & > div {
      border: none;
      background: rgba(254, 230, 239, 0.26);
      padding: 0.68rem 0.5rem;
      font-weight: bold;
    }
  }

  input {
    border: none;
    border: 1px solid #cbcbcb;
    padding: 1rem;
    flex: 1;
    font-size: 1.15rem;
    color: #171922;
    max-width: 418px;
    width: 100%;
    background: #fff;
    border-radius: 4px 0 0 4px;
    border-right: none;
    font-weight: bold;

    &: [disabled] {
      opacity: 0.5;
    }
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 0.5rem;

    input {
      width: 100%;
      height: 56px;
    }

    .select {
      width: 120px;
    }
  }

  ${media.lessThan("tablet")} {
    width: 100%;

    input {
      max-width: 100%;
      width: 100%;
    }
  }

  ${media.lessThan("small")} {
    .select {
      flex-shrink: 0;
    }
  }
`;

export const CurrencyWrapper = styled.div`
  width: 130px;
  height: 56px;
  background: rgba(254, 230, 239, 0.26);
  border: 1px solid #cbcbcb;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding: 14px 1rem;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  // @media (min-width: 1200px) and (max-width: 1600px) {
  //   height: 56px;
  // }
`;
