import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useToasts } from "react-toast-notifications";

import FlexWrapper from "../../components/FlexWrapper";
import Img from "../../components/Img";
import { TabletOrDesktop } from "../../components/responsive/responsive";
import {
  userIdSelector,
  userSelector,
  walletDataSelector,
} from "../../redux/selectors";
import { ContentWrapper } from "./WalletToWallet.styled";
import Destination from "./Parts/Destination";
import Details from "./Parts/Details";
import Review from "./Parts/Review";
import {
  RequestWalletTransfer,
  TransferToClient,
} from "../../services/Services";
import { isEmpty, replaceComma } from "../../utils/helpers";
import TwoFactorModal from "../../components/TwoFactor/Index";
import { setLoading } from "../../redux/commonSlice";
import ResultModal from "../../components/ResultModal";
import { setWalletTransferData } from "../../redux/transferSlice";
import InterruptionModal from "../../components/InterruptionModal";
import { SaveasTempalateWrapper } from "../../components/ResultModal/ResultModal.styled";
import Text from "../../components/Text/text";
import ProgressLines from "../../components/ProgressLines/ProgressLines";
import { useToast } from "../../hooks/useToast";

const WalletToWallet = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const userId = useSelector(userIdSelector);
  const navigate = useNavigate();
  const walletData = useSelector(walletDataSelector);
  const [receiverId, setReceiverId] = useState("");
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const [transferId, setTransferId] = useState<any>();
  const user = useSelector(userSelector);
  const prevActiveTab = useRef(0);
  const [animationClass, setAnimationClass] = useState("");
  const toast = useToast()

  useEffect(() => {
    dispatch(setWalletTransferData({ reset: true }));
  }, []);

  const handleGoBack = () => {
    if (isEmpty(walletData)) {
      navigate("/home");
      dispatch(setWalletTransferData({ reset: true }));
    } else {
      setIsInterruptionOpen(true);
    }
  };

  const handleRequest = () => {
    dispatch(setLoading(true));
    RequestWalletTransfer(
      userId,
      walletData.countryId,
      walletData.accountId,
      walletData.phone,
      parseFloat(replaceComma(walletData?.amount)),
      walletData.currency,
      walletData.receiverId
    )
      .then((res) => {
        setReceiverId(res.data.Content.ReceiverId);
        setIsTwofactorOpen(true);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const makeTransferToClient = (pin: string) => {
    if (pin?.length === 6) {
    
    TransferToClient(
      replaceComma(walletData.amount),
      userId,
      receiverId,
      walletData.accountId,
      walletData.receiverId,
      pin,
      walletData?.paymentDetails
    )
      .then((res) => {
        setTransferId(res.data.Content.TransferId);
        setMode("success");
        setIsTwofactorOpen(false);
        setIsResultModalOpen(true);
      })
      .catch((err) => {
        setMode("fail");
        if (err.response.data.errors) {
          Object.values(err.response.data.errors).forEach((errorArray: any) => {
            errorArray.forEach((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });} else if (pin?.length > 0 && pin?.length < 6){
        toast(t("errorboundary.smspin"), "error");
      }
  };

  useEffect(() => {
    if (activeTab > prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-right duration-500");
    } else if (activeTab < prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-left duration-500");
    } else {
      setAnimationClass("");
    }
    prevActiveTab.current = activeTab;
  }, [activeTab]);

  const additionalContent = () => {
    return mode === "success" ? (
      <SaveasTempalateWrapper>
        <Img width="20px" src={`/sidebar/new/Logo-tablet.svg`} alt="logo" />
        <Text color={"var(--black)"} mt="0.5rem">
          {walletData?.ReceiverInitials}
        </Text>
        <Text color={"var(--black)"} mt="0.1rem">
          {walletData?.amount} {walletData?.currency}
        </Text>
        <Text color={"var(--black)"} mt="0.1rem">
          {walletData?.countryCodeText} {walletData?.phone}
        </Text>
      </SaveasTempalateWrapper>
    ) : (
      <></>
    );
  };

  return (
    <ContentWrapper>
      <Tabs selectedIndex={activeTab}>
        <FlexWrapper align="center" justify="between" mb="1.25rem">
          <TabletOrDesktop>
            <div onClick={handleGoBack}>
              <Img className="logo" src={"/logo.svg"} alt="logo" />
            </div>

            <ProgressLines
              maxWidth="720px"
              activeTab={activeTab}
              tabs={[
                {
                  title: "wallettowallet.tab1",
                },
                {
                  title: "wallettowallet.tab2",
                },
                {
                  title: "wallettowallet.tab3",
                },
              ]}
              progress={progress}
              setActiveTab={(index: number) => setActiveTab(index)}
            />

            <div className="close" onClick={handleGoBack}>
              <CloseRoundedIcon />
            </div>
          </TabletOrDesktop>
        </FlexWrapper>

        <TabPanel key={`tab-panel-0-${activeTab}`} className={`animate-fadeIn`}>
          <Destination
            onNextPress={() => {
              setProgress(1);
              setActiveTab(1);
            }}
            onPreviousPress={handleGoBack}
          />
        </TabPanel>
        <TabPanel
          key={`tab-panel-1-${activeTab}`}
          className={`tab-content ${animationClass}`}
        >
          <Details
            onPreviousPress={() => setActiveTab(0)}
            onNextPress={() => {
              setProgress(2);
              setActiveTab(2);
            }}
          />
        </TabPanel>
        <TabPanel
          key={`tab-panel-2-${activeTab}`}
          className={`tab-content ${animationClass}`}
        >
          <Review
            onPreviousPress={() => setActiveTab(1)}
            onConfirmPress={() => {
              handleRequest();
            }}
          />
        </TabPanel>
      </Tabs>
      {isTwofactorOpen && (
        <TwoFactorModal
          displayCode={user?.clientPhone}
          isOpen={isTwofactorOpen}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) => makeTransferToClient(pin)}
          onResend={() => {
            handleRequest();
          }}
        />
      )}

      <ResultModal
        mode={mode}
        transferId={transferId}
        isOpen={isResultModalOpen}
        additionalContent={additionalContent}
        type="wallettowallet"
        fileName="wallet-transfer-document"
        showPdfDownloadButton={true}
        onClose={() => {
          setIsResultModalOpen(false);
          navigate("/home");
        }}
      />

      <InterruptionModal
        title={t("wallettowallet.wallettransactions")}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          dispatch(setWalletTransferData({ reset: true }));
          navigate("/home");
          setIsInterruptionOpen(false);
        }}
      />
    </ContentWrapper>
  );
};

export default WalletToWallet;
