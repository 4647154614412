import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import Img from "../../../components/Img";
import { media } from "../../../utils/theme";
import { useSelector } from "react-redux";
import { billPaymentDataSelector } from "../../../redux/selectors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 1.5rem;
  font-size: 14px;
  flex-wrap: wrap;

  span {
    cursor: pointer;
  }

  .chevron {
    fill: #d0d5dd;
  }

  .active {
    background: #fff5f6;
    padding: 6px;
    color: var(--red);
  }

  ${media.lessThan("small")} {
    padding: 8px 16px;
  }
`;

const Breadcrumbs = ({ serviceName, level, onClick, isTemplate }: any) => {
  const { t } = useTranslation();
  const billData = useSelector(billPaymentDataSelector);
  const navigate = useNavigate();
  const isFromTemplate = billData?.isFromTemplate;

  if (isTemplate) {
    return (
      <Wrapper>
        <Link to="/home">
          <Img src="/sidebar/new/home.svg" alt="home" />
        </Link>
        <ChevronRightOutlinedIcon className="chevron" />
        <Link to="/billpayments">{t("billpayments.billpayments")}</Link>
        <ChevronRightOutlinedIcon className="chevron" />
        <div className="active">
          <span>{t("banktransfer.mytemplates")}</span>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Link to="/home" className="shrink-0">
        <Img src="/sidebar/new/home.svg" alt="home" />
      </Link>
      <ChevronRightOutlinedIcon className="chevron" />
      <Link className="font-medium" to="/billpayments">
        {t("billpayments.billpayments")}
      </Link>
      {isFromTemplate && (
        <>
          <ChevronRightOutlinedIcon className="chevron" />
          <div
            className={level === 3 ? "active" : ""}
            onClick={() => navigate("/billpayments/templates")}
          >
            <span className="font-medium">{t("banktransfer.mytemplates")}</span>
          </div>
        </>
      )}
      {level > 2 && !isFromTemplate && (
        <>
          <ChevronRightOutlinedIcon className="chevron" />
          <div
            className={level === 3 ? "active" : ""}
            onClick={() => onClick(3)}
          >
            <span className="font-medium">{t("billpayments.searchtype")}</span>
          </div>
        </>
      )}
      {level > 3 && serviceName && (
        <>
          <ChevronRightOutlinedIcon className="chevron" />
          <div
            className={level === 4 ? "active" : ""}
            onClick={() => onClick(4)}
          >
            <span className="font-medium">{serviceName}</span>
          </div>
        </>
      )}

      {level > 4 && (
        <>
          <ChevronRightOutlinedIcon className="chevron" />
          <div className={level === 5 ? "active" : ""}>
            <span>{t("billpayments.paymentdetails")}</span>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default Breadcrumbs;
