import React from "react";
import cx from "classnames";

type TitleVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "title"
  | "title1";

interface TitleProps {
  variant: TitleVariant;
  children: React.ReactNode;
  className?: string;
}

const variantStyles: Record<TitleVariant, string> = {
  h1: "text-[40px] font-bold caps text-[#15151D]",
  h2: "text-4xl font-bold caps text-[#15151D]",
  h3: "text-[2rem] font-bold caps text-[#15151D]",
  h4: "text-2xl font-bold caps text-[#15151D]",
  h5: "text-xl font-bold caps  text-[#15151D]",
  h6: "text-base font-bold caps text-[#15151D]",
  title1: "text-base font-bold text-[#15151D]",
  title: "text-[18px] font-bold text-[#15151D]",
};

const Title: React.FC<TitleProps> = ({ variant, children, className }) => {
  const Tag = (variant?.startsWith('h') ? variant : 'p') as keyof JSX.IntrinsicElements;
  return (
    <Tag
      className={cx(
        variantStyles[variant],
        className
      )}
    >
      {children}
    </Tag>
  );
};

export default Title;
