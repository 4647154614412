import { useTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import FlexWrapper from "../../../components/FlexWrapper";
import Img from "../../../components/Img";
import Box from "../../../components/Tailwind/Box";
import Title from "../../../components/Tailwind/Title";
import Text from "../../../components/Tailwind/Text";

export const AccountNotApproved = ({ onNextClick }: any) => {
  const { t } = useTranslation();

  return (
    <Box className="shadow-[0px_2px_3px_0px_rgba(111,118,137,0.10)] !border-0 mx-4 md:mx-0">
      <Title variant="h3" className="text-center mb-6 mt-4">
        {t("registration.bls.notapprovedtitle")}{" "}
      </Title>
      <div className="text-center">
        <Img
          className="max-w-[225px] py-10 mb-6"
          src="/bls/fail.gif"
          alt="fail"
        />
      </div>

      <Box className="!bg-[#F7F7F7] !p-4 max-w-[700px] mx-auto">
        <FlexWrapper gap="8px" mb="0.5rem" align="center">
          <ErrorOutlineOutlinedIcon style={{ fill: "#212121", width: 18 }} />
          <Title variant="h6">{t("registration.bls.restricted")}</Title>
        </FlexWrapper>

        <Text className="mb-2 text-[#56575B]" variant="body2">
          {t("registration.bls.notapprovedtext")}
        </Text>
      </Box>

      <button
        className="w-[380px] h-[38px] leading-4 mt-10 max-w-full  mx-auto btn btn-red text-base mb-4 caps"
        onClick={onNextClick}
      >
        {t("common.backtohome")}
      </button>
    </Box>
  );
};
