import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import Title from "../../components/Text/title";
import { TabsWrapper, Wrap } from "./Manage.styled";
import Accounts from "./parts/Accounts";
import Notifications from "./parts/Notifications/Accounts";
import Templates from "./parts/Templates";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";
import FlexWrapper from "../../components/FlexWrapper";
import { useNavigate } from "react-router";

const Manage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  return (
    <>
      <Wrap>
        <FlexWrapper mb="0" gap="0.45rem">
          <Mobile>
            <ArrowBackOutlinedIcon
              onClick={() => navigate("/home")}
              className="cursor-pointer"
            />
          </Mobile>
          <Title
            size="1.5rem"
            color="#171922"
            weight={700}
            className="title !ml-0 mb-2 !mt-[22px] w-[calc(100%-111px)] [@media(max-width:554px)]:!my-0"
          >
            {t("manage.title")}
          </Title>
        </FlexWrapper>
      </Wrap>
      <TabsWrapper>
        <Tabs selectedIndex={activeTab}>
          <TabList className="tablist !mt-0">
            <Tab
              role="menuitem"
              tabIndex="0"
              className={`${activeTab === 0 ? "active" : "through"}`}
              onClick={() => setActiveTab(0)}
            >
              {t("manage.tab1")}
            </Tab>
            <Tab
              role="menuitem"
              tabIndex="0"
              selected={activeTab === 1}
              className={`${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              {t("manage.tab2")}
            </Tab>
            <Tab
              role="menuitem"
              tabIndex="0"
              selected={activeTab === 2}
              className={`${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              {t("manage.tab3")}
            </Tab>
            <Tab
              role="menuitem"
              tabIndex="0"
              selected={activeTab === 3}
              className={`${activeTab === 3 ? "active" : "through"}`}
              onClick={() => setActiveTab(3)}
            >
              {t("manage.tab4")}
            </Tab>
          </TabList>
          <TabPanel>
            <Notifications />
            <Accounts />
            <Templates />
          </TabPanel>
          <TabPanel>
            <Accounts />
          </TabPanel>
          <TabPanel>
            <Notifications />
          </TabPanel>
          <TabPanel>
            <Templates />
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

export default Manage;
