import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    :root {
      --white: #fff;
      --black: #20202C;
      --red: #FF4767;
      --text-color: #171922;
      --text-color-2: #757582;
      --purple: #7D69FC;
      --light-gray: #FCFCFC;
      --border-color: #E4E4E4;
      --border-color-light: #E6E6E6;
      --light-red: #FF4767;
      --light-bg: #FCFCFC;
      --pink: #FFF7F7;
      --light-blue-bg: #F9F8FF;
      --dark-blue: #3338A8;
      --green: #2B8233;
      --light-green: #319E50;
      --blue: #2C52DA;
      --yellow: #EEC63D;
    }


      //reset css
      html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td,
      article,
      aside,
      canvas,
      details,
      embed,
      figure,
      figcaption,
      footer,
      header,
      hgroup,
      menu,
      nav,
      output,
      ruby,
      section,
      summary,
      time,
      mark,
      audio,
      video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }
    body {
      line-height: 1;
      color: var(--text-color);
    }
    ol,
    ul {
      list-style: none;
    }
    blockquote,
    q {
      quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: "";
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    * {
      box-sizing: border-box;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    img {
      max-width: 100%;
      display: inline-block;
    }

    input,
    button,
    button:active{
      outline: none !important;
    }

    ::-webkit-input-placeholder { 
      color: #9C9C9C !important;
      font-size: 14px !important;
    }
    
    :-ms-input-placeholder { 
      color: #9C9C9C !important;
      font-size: 14px !important;
    }
    
    ::placeholder {
      color: #9C9C9C !important;
      font-size: 14px !important;
    }


  /*** main styles **/
  html,
  body {  
   
    width: 100%;
    height: 100%;
    font-size: 16px; 
    font-family: Inter;
    background: #fbfbfb;

    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }

  .notosans,
  .notosans button{
    font-family: notosans !important;
  }

  ::placeholder {
    color: var(--primary-blue);
    font-size: 1rem;
  }

  .w-100{
    width: 100%;
  }

  .h-100{
    height: 100%;
  }
 
  .font-uppercase{
    font-feature-settings: 'case' on;
    text-transform: uppercase;
  }
  
  .shrink-0 {
    flex-shrink: 0;
  }

  .rotated{
    transform: rotate(180deg);
  }

  .dropdown-content{
    z-index: 2 !important;
  }

  .react-datepicker-popper{
    z-index: 100;
  }

  .rmsc .dropdown-heading{
    padding: 25px 15px;
  }

  .rc-tooltip{
    z-index: 99999;
    opacity: 1;
  }

  .text-center {
    text-align: center
  }

  .text-right {
    text-align: right;
  }

  .react-toast-notifications__toast__icon-wrapper{
    display: none;
  }

  .uppercase{
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .pointer {
    cursor: pointer;
  }


  .flex-1 {
    flex: 1;
  }

  .btn{
    display: block;
    border-radius: 4px;
    padding: 10px;
    border: none;
    outline: none !important;
    transition: opacity 0.25s;
    cursor: pointer;
    transition: background 0.25s;

    &:focus{
      outline: 2px solid var(--text-color) !important;
    }

    &:active{
      outline: none !important;
    }
  }

  .btn-green{
    background: var(--green);
    color: #fff;

    &:hover{
      background: var(--dark-green);
    }
  }

  .btn-red{
    background: var(--red);
    color: #fff;
    border-radius: 4px;
    outline: none;

    &:hover{
      background: #EFA3B0;
    }

    &:disabled{
      background: var(--border-color);
      cursor: auto;
    }

    &:focused{
      background: var(--red);
      border: 1px solid #2B2D42;
    }
  }

  .btn-plain{
    background: transparent;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--text-color);

    &:hover{
      opacity: 0.9;
    }
  }

  textarea {
    font-family: aspira;
  }


  /** Flex Classes **/
  .d-flex{
    display: flex !important;
  }

  .justify-content-center{
    justify-content: center !important;
  }

  .align-items-center{
    align-items: center !important;
  }

  .justify-content-end{
    justify-content: flex-end !important;
  }


  .rc-tooltip-arrow{
    border-top-color: var(--light-red) !important;
  }

  .print-body{
    padding: 30px;

    li{
      font-size: 11px;
    }
  }

  @media print {
    .hidden-print {
      display: none !important;
    }

    li.report-print{
      padding: 0.875rem 0.5rem;
      width: 1009px;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      cursor: pointer;

      &:hover{
          background: #E4E4E4 !important;
      }

      span{
          flex: 1;
      }

      div.operation-name{
        padding: 10px 0;
        text-overflow: default;
        overflow: visible;
        white-space: normal;
      }

      .amount{
          color: var(--green);
      }
  }

  li:nth-child(2n + 2){
      background: #FFF7F7;
  }
  }

  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }

  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }

  @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
  }

  .spin{
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
  }


  .ReactModal__Content{
    overflow-Y: auto !important;
    overflow-X: hidden !important;

  }

  .loading-indicator:before {
    content: '';
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: var(--red);
    text-align:center;
    font-weight:bold;
    font-size:1.5rem;        
}

@media (max-width: 556px) {
  .fb_dialog iframe{
    bottom: 75px !important;
  }
}

.ReactModal__Body--open{
  overflow:hidden;

}

`;

export default GlobalStyles;
