import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Divider from "../../../components/Divider";
import Img from "../../../components/Img";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { userSelector } from "../../../redux/selectors";
import InterruptionModal from "../../../components/InterruptionModal";
import {
  Wrapper,
  CardWrapperNew,
  SliderWrapper,
} from "./IntelexpressCard.styled";
import {
  BlockIECard,
  CheckCardStatus,
  UnblockIECard,
  changeLimit,
  getLoanDetails,
} from "../../../services/Services";
import { formatBalance, getCurrencySymbol } from "../../../utils/helpers";

import ResetModal from "../Accounts/Resetmodal";
// import BlockModal from "../AccountTabs/BlockModal";
// import CardModal from "../AccountTabs/Cardmodal";
import NoResult from "./NoResult";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardModal from "./CardModal/Cardmodal";
import ManageLimitModal from "./CardModal/ManageLimitModal";
import ProductModal from "./CardModal/ProductModal";
// import TwoFactorModal from "../../../components/TwoFactor/Index";
import ManageLimitResultModal from "./CardModal/ManageLimitResultModal";
import { setLoading } from "../../../redux/commonSlice";
import { getUserId } from "../../../utils/storage";
import { Skeleton } from "@mui/material";

export type IECard = {
  AccountId: string;
  AvailableAmount: number;
  Available?: number;
  BlockedAmount: number;
  CardCategory: string;
  CardHolderName: string;
  CardId: number;
  CardNumber: string;
  CardStatus: string;
  CardType: string;
  CreditLimit: number;
  Currency: string;
  CurrentBalance: number;
  DueAmount: number;
  DuePercent: number;
  DueSum: number;
  ExpiryDate: string;
  IsPrimary: boolean;
};

type CreditCardProps = {
  IECards: IECard[];
  isLoading: boolean;
  handleRefetch: () => void;
  // onBlock: () => void;
};

const IntelexpressCreditCard = ({
  IECards,
  isLoading,
  handleRefetch,
}: // onBlock,
CreditCardProps) => {
  const [activeItem, setActiveItem] = useState<any>(null);
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const userId = getUserId();
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [isUnblockModalOpen, setIsUnblockModalOpen] = useState(false);
  const { addToast } = useToasts();
  const additionalCards = IECards?.filter(
    (item: IECard) => item.CardType === "დამატებითი"
  );
  const [manageLimitOpen, setManageLimitOpen] = useState(false);
  const [manageLimitResultOpen, setManageLimitResultOpen] = useState(false);
  const dispatch = useDispatch();
  const [previousCard, setPreviousCard] = useState("");
  const [hideSection, setHideSection] = useState(false);
  const [details, setDetails] = useState<any>();

  var settings = {
    dots: IECards?.length > 1 ? true : false,
    arrows: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setTimeout(() => {
        setActiveItem(IECards[newIndex]);
      }, 250);
    },
  };

  useEffect(() => {
    if (IECards?.length) {
      setActiveItem(IECards[0]);
    }
  }, [IECards]);

  useEffect(() => {
    CheckCardStatus(userId)
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 409) {
          setHideSection(true);
        }
      });
  }, [userId]);

  useEffect(() => {
    if (activeItem?.AccountId) {
      getLoanDetails(userId)
        .then((res) => setDetails(res.data.Content))
        .then((err) => {});
    }
  }, [activeItem]);

  const handleCardUnblock = () => {
    UnblockIECard(user.userId, activeItem.CardNumber)
      .then((res) => {
        setIsUnblockModalOpen(false);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        handleRefetch();
        setCardModalOpen(true);
        // onBlock();
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleCardBlock = () => {
    BlockIECard(user.userId, activeItem.CardNumber, "Blocking")
      .then((res) => {
        setBlockModalOpen(false);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        handleRefetch();
        setCardModalOpen(true);
        // onBlock();
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleLimitChange = (
    userId: string,
    CardNFrom: string,
    CardNTo: string,
    amount: number
  ) => {
    dispatch(setLoading(true));
    setManageLimitOpen(false);
    changeLimit(userId, CardNFrom, CardNTo, amount)
      .then((res) => {
        dispatch(setLoading(false));
        setManageLimitOpen(false);
        setManageLimitResultOpen(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  if (isLoading) {
    return (
      <Wrapper className="flex gap-4 items-start !p-4">
        <div className="">
          <Skeleton
            variant="rectangular"
            className="rounded-lg"
            width={190}
            height={114}
          />
        </div>
        <div>
          <Skeleton variant="text" height={20} width={120} />
          <Skeleton variant="text" className="mt-4" height={30} width={80} />
          <Skeleton
            variant="text"
            className="!mt-[20px]"
            height={50}
            width={120}
          />
        </div>
      </Wrapper>
    );
  }

  if (hideSection && !IECards?.length) {
    return null;
  }

  return (
    <>
      <Title
        mt="1rem"
        ml="1rem"
        mb="4px"
        size="0.875rem"
        weight="500"
        className="uppercase"
      >
        {t("intelexpresscard.intelexpresscard")}
      </Title>
      <Divider mb="1rem" width="100%" />

      {IECards?.length ? (
        <div>
          <CardWrapperNew>
            <SliderWrapper>
              <Slider {...settings}>
                {IECards?.map((item: IECard) => (
                  <div className="shrink-0" key={item.AccountId}>
                    <Img src="/home/cards/card.svg" alt="card" />
                  </div>
                ))}
              </Slider>
            </SliderWrapper>
            <div>
              <Title color="#CDCDCD" mb="0.2rem" className="h-[34px]">
                {t("intelexpresscard.intelexpresscard")}{" "}
                {!activeItem?.IsPrimary && (
                  <Text size="11px" color="#CDCDCD" mb="0" lh="11px">
                    {t("intelexpresscard.additionalcard")}
                  </Text>
                )}
              </Title>
              <Text color="#fff" weight="bold" size="20px">
                {getCurrencySymbol(activeItem?.Currency)}{" "}
                {formatBalance(activeItem?.AvailableAmount)}
              </Text>
              <Title
                size="14px"
                // mt={activeItem?.IsPrimary ? "1.40rem" : "0.75rem"}
                mb="0"
                ta="center"
                className="pointer uppercase showdetails sm:block inline-block !mt-4"
                color="#fff"
                style={{
                  border: "1px solid #E4E4E4",
                  borderRadius: 4,
                  padding: "7px 10px",
                }}
                onClick={() => {
                  setActiveItem(activeItem);
                  setCardModalOpen(true);
                }}
              >
                {t("intelexpresscard.showcarddetails")}
              </Title>
            </div>
          </CardWrapperNew>
        </div>
      ) : (
        <NoResult onApply={() => setHideSection(true)} />
      )}

      <ResetModal
        isOpen={resetModalOpen}
        onClose={() => {
          setCardModalOpen(true);
          setResetModalOpen(false);
        }}
        cardN={activeItem?.CardNumber}
        phone={user.clientPhone}
      />

      <InterruptionModal
        title={
          activeItem?.CardIsActive
            ? t("intelexpresscard.blockcard")
            : t("intelexpresscard.unblockcard")
        }
        text={t("cards.blocktext")}
        actionText={t("common.yes")}
        closeText={t("common.no")}
        isOpen={blockModalOpen}
        onStayClick={() => {
          activeItem?.CardIsActive ? handleCardBlock() : handleCardUnblock();
        }}
        onCloseClick={() => {
          setBlockModalOpen(false);
          setIsUnblockModalOpen(false);
        }}
      />
      <CardModal
        details={details}
        onAdditionalClick={(additionalCard: IECard) => {
          setPreviousCard(activeItem);
          setActiveItem(additionalCard);
        }}
        onBackClick={() => setActiveItem(previousCard)}
        additionalCards={additionalCards}
        isOpen={cardModalOpen}
        activeItem={activeItem}
        onClose={() => {
          setCardModalOpen(false);
        }}
        onBlockCard={() => {
          setCardModalOpen(false);
          activeItem.CardIsActive
            ? setBlockModalOpen(true)
            : setIsUnblockModalOpen(true);
          // onBlock();
        }}
        onManageLimit={() => {
          setCardModalOpen(false);
          setManageLimitOpen(true);
        }}
        onResetPin={() => {
          setCardModalOpen(false);
          setResetModalOpen(true);
        }}
        onProductPress={() => {
          setCardModalOpen(false);
          setProductModalOpen(true);
        }}
      />
      <ManageLimitModal
        IECards={IECards}
        activeItem={activeItem}
        onConfirm={handleLimitChange}
        isOpen={manageLimitOpen}
        onClose={() => setManageLimitOpen(false)}
      />
      <ProductModal
        details={details}
        activeItem={activeItem}
        isOpen={productModalOpen}
        onClose={() => setProductModalOpen(false)}
      />
      <InterruptionModal
        title={`${t("intelexpresscard.unblockcard")}`}
        text={t("intelexpresscard.unlocktext")}
        actionText={t("common.continue")}
        isOpen={isUnblockModalOpen}
        onStayClick={handleCardUnblock}
        onCloseClick={() => {
          setIsUnblockModalOpen(false);
        }}
      />
      <ManageLimitResultModal
        isOpen={manageLimitResultOpen}
        onClose={() => setManageLimitResultOpen(false)}
      />
      {/* {isTwofactorOpen && (
        <TwoFactorModal
          isOpen={isTwofactorOpen}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) => makeTransferToClient(pin)}
          onResend={() => {}}
        />
      )} */}
    </>
  );
};

export default IntelexpressCreditCard;
