import styled from "styled-components";

import { media } from "../../../../utils/theme";

export const ReviewForm = styled.div<{ disabled?: boolean }>`
  background: #fff;

  border-radius: 10px;
  width: 500px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  border: 1px solid #eaecf0;
  margin: 1.5rem auto;
  margin-bottom: 84px !important;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin: 0 auto;
    margin-top: 1rem;

    width: 100%;
    max-width: 80%;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .contain {
      padding: 10px 0;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;
