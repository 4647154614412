import axiosInstance, { baseURL } from "./axios";
import axios from "axios";

import { getToken } from "../utils/storage";

// Auth Services
export async function LoginServiceStart(email: string, password: string) {
  return await axiosInstance.post(`/authentication/login`, {
    usernameOrEmail: email,
    password,
  });
}

export async function LoginServiceFinal(email: string, twoFactorCode: string) {
  return await axiosInstance.post(`/authentication/authorization`, {
    usernameOrEmail: email,
    twoFactorCode,
  });
}

export async function LogoutService() {
  return await axiosInstance.post(`/authentication/logout`, {});
}

export async function checkBLSStatus(token: string) {
  return await axiosInstance.post(`/clientstatus/check`, {
    token
  });
}



export async function checkToken() {
  return await axiosInstance.get(`/authentication/check`, {});
}

export async function changeLanguageService(
  userId: string,
  languageId: number
) {
  return await axiosInstance.put(`/profile/language`, {
    userId,
    languageId,
  });
}

export async function GetAvailableLanguages() {
  return await axiosInstance.get("/profile/languages/available");
}

export async function ResetPasswordForAuthorizedUser(
  OldPassword: string | null,
  NewPassword: string | null
) {
  return await axiosInstance.put(`/password/updateforloggedinuser`, {
    OldPassword,
    NewPassword,
  });
}

export async function checkUserName(userName: string) {
  return await axiosInstance.post("/registration/checkusername", { userName });
}

export async function checkEmail(email: string) {
  return await axiosInstance.post("/registration/checkemail", { email });
}

export async function RegisterWithCode(
  firstName: string,
  lastName: string,
  userName: string,
  email: string,
  password: string,
  phoneNumber: string,
  smsCode: string,
  countryId: number,
  hasAgreedToTerms: boolean
) {
  return await axiosInstance.post("/registration/registerwithcode", {
    firstName,
    lastName,
    userName,
    email,
    password,
    phoneNumber,
    smsCode,
    countryId,
    hasAgreedToTerms,
  });
}

// Identomat
export async function VerificationSession(token: string) {
  return await axiosInstance.post("/verification/session", {
    token,
  });
}

export async function VerificationSessionGet(token: string) {
  return await axiosInstance.get(
    `/verification/session?session_token=${token}`
  );
}

export async function GetCountryCodes() {
  return await axiosInstance.get("/countrycodes");
}

export async function CheckMobileNumber(
  phoneNumber: string,
  countryId: number
) {
  return await axiosInstance.post("/registration/phoneverification", {
    phoneNumber,
    countryId,
  });
}

export async function ConfirmEmailWithCode(email: any, code: any, key: any) {
  return await axiosInstance.post(`/email/confirmation/code`, {
    key,
    email,
    code,
  });
}

export async function ResendEmailWithCode(email: string) {
  return await axiosInstance.post(`/email/resendconfirmation/code`, {
    userNameOrEmail: email,
  });
}

// New Reset password
export async function PasswordResetGetInfo(EmailOrUsername: string) {
  return await axiosInstance.post(`/password/reset/info`, {
    EmailOrUsername,
  });
}

export async function PasswordResetRequest(
  EmailOrUsername: string,
  resetType: string
) {
  return await axiosInstance.post(`/password/reset/request`, {
    EmailOrUsername,
    resetType,
  });
}

export async function PasswordResetVerify(
  EmailOrUsername: string,
  token: string,
  code: string
) {
  return await axiosInstance.post(`/password/reset/verify`, {
    code,
    token,
    EmailOrUsername,
  });
}

export async function PasswordResetComplete(
  newPassword: string,
  EmailOrUsername: string,
  token: string,
  code: string
) {
  return await axiosInstance.post(`/password/reset/complete`, {
    code,
    token,
    EmailOrUsername,
    newPassword,
  });
}

export async function getAllBalances(userId: string) {
  return await axiosInstance.get(`/Account/Balances?userId=${userId}`);
}

export async function getTotalBalance(userId: string) {
  return await axiosInstance.get(`/account/totalbalance?userId=${userId}`);
}

// User

export async function GetUserAccounts(userId: string) {
  return await axiosInstance.get(`/account/accounts?userId=${userId}`);
}

export async function GetGreekAccounts(userId: string) {
  return await axiosInstance.get(`/account/greece/accounts?userId=${userId}`);
}

export async function GetGreekBalances(userId: string) {
  return await axiosInstance.get(`/account/greece/balances?userId=${userId}`);
}

export async function GetFavouriteAccount(userId: string) {
  return await axiosInstance.get(`/account/favourite?userId=${userId}`);
}

export async function SetFavouriteAccount(userId: string, accountId: string) {
  return await axiosInstance.post(`/account/favourite`, {
    userId,
    accountId,
  });
}

//Transfers - Withdraw
export async function SearchTransferForWithdraw(
  transferCode: string,
  userId: string
) {
  return await axiosInstance.post(`/moneytransfer/searchforwithdrawal`, {
    transferCode,
    userId,
  });
}

export async function WithdrawTransfer(userId: string, transferCode: string) {
  return await axiosInstance.post(`/moneytransfer/withdrawal`, {
    transferCode,
    userId,
  });
}

export async function verifyConsent(token: string) {
  return await axiosInstance.get(`/consents/verify`, {
    params: {
      token,
    },
  });
}

export async function submitConsent(token: string, consent: boolean) {
  return await axiosInstance.post(`/consents/submit`, {
    token,
    consent,
  });
}

//Transfers - send
export async function GetOffices(countryId: string) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/offices/${countryId}`
  );
}

export async function GetCountryDescription(countryId: string) {
  return await axiosInstance.get(
    `countrycodes/moneytransfer/countries/${countryId}/description`
  );
}

export async function calculateMoneytransferCommision(
  userId: string,
  DebitAccountId: string,
  CreditCurrency: string,
  creditAmount?: number,
  debitAmount?: number,
  DestinationOfficeCode?: number
) {
  if (debitAmount) {
    return await axiosInstance.get(
      `moneytransfer/calculatecommission?userId=${userId}&DebitAccountId=${DebitAccountId}&CreditCurrency=${CreditCurrency}&DebitAmount=${debitAmount}&DestinationOfficeCode=${DestinationOfficeCode}`
    );
  }

  return await axiosInstance.get(
    `moneytransfer/calculatecommission?userId=${userId}&DebitAccountId=${DebitAccountId}&CreditCurrency=${CreditCurrency}&CreditAmount=${creditAmount}&DestinationOfficeCode=${DestinationOfficeCode}`
  );
}

export async function GetTemplates() {
  return await axiosInstance.get(`/MoneyTransfer/GetTemplates`);
}

export async function deleteTemplate(userId: string, templateId: string) {
  return await axiosInstance.delete(`/moneyTransfer/deletetemplate`, {
    data: {
      templateId,
      userId,
    },
  });
}

export async function CreateSendRequest(data: any) {
  return await axiosInstance.post(`/moneyTransfer/create`, {
    userId: data.userId,
    accountId: data.accountId,
    creditAmount: data.creditAmount,
    debitAmount: data.amount,
    exchangeRate: data.exchangeRate,
    recipientCurrency: data.recipientCurrency,
    isDataSharingApproved: true,
    destinationOfficeCode: data.destinationOfficeId,
    receiver: {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      countryId: data.country,
      country: data.countryName,
      alpha2: data.countryCode,
    },
  });
}

export async function CreateSendRequestFromTemplate(
  data: any,
  activeTemplateId: number
) {
  return await axiosInstance.post(`/moneyTransfer/createfromtemplate`, {
    accountId: data.accountId,
    userId: data.userId,
    creditAmount: data.creditAmount,
    debitAmount: data.amount,
    exchangeRate: data.exchangeRate,
    recipientCurrency: data.recipientCurrency,
    isDataSharingApproved: true,
    templateId: activeTemplateId,
  });
}

export async function ApproveTransfer(
  userId: string,
  transferId: string | number,
  twoFactorCode: string
) {
  return await axiosInstance.post(`/moneytransfer/approve`, {
    userId,
    transferId,
    twoFactorCode,
  });
}

export async function SearchTransferForEdit(
  userId: string,
  transferCode: string
) {
  return await axiosInstance.post(`/moneytransfer/searchforupdate`, {
    userId,
    transferCode,
  });
}

export async function RequestTransferEdit(
  transferCode: string,
  userId: string
) {
  return await axiosInstance.put(`/moneytransfer/requestforupdate`, {
    transferCode,
    userId,
  });
}

export async function EditTransfer(
  userId: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  twoFactorCode: string,
  transferCode: string
) {
  return await axiosInstance.put(`/moneytransfer/update`, {
    userId,
    firstName,
    lastName,
    phoneNumber,
    twoFactorCode,
    transferCode,
  });
}

export async function CancelTransfer(userId: string, transferCode: string) {
  return await axiosInstance.post(`/moneytransfer/cancel`, {
    transferCode,
    userId,
  });
}

export async function CreateSendTemplate(
  templateName: string,
  firstName: string,
  lastName: string,
  officeCode: number,
  phoneNumber: string,
  countryCodeId: number,
  userId: string
) {
  return await axiosInstance.post(`/moneyTransfer/createtemplate`, {
    templateName,
    firstName,
    lastName,
    officeCode,
    phoneNumber,
    countryCodeId,
    userId,
  });
}

export async function DeleteSendTemplate(templateId: number, userId: string) {
  return await axiosInstance.post(`/moneyTransfer/deletetemplate`, {
    templateId,
    userId,
  });
}

// Topup
export async function TopupWithCard(
  amount: number,
  platform: string,
  accountId: number,
  saveCard?: boolean,
  savedCardId?: string
) {
  return await axiosInstance.post(`topup/`, {
    amount,
    platform,
    saveCard,
    savedCardId,
    accountId,
  });
}

export async function getTopupResult(cardOperationId: string) {
  return await axiosInstance.get(`topup/${cardOperationId}`);
}

export async function getTopupCards() {
  return await axiosInstance.get(`cardoperation/cards`);
}

export async function deleteTopupCard(cardId: string) {
  return await axiosInstance.delete(`cardoperation/cards/${cardId}`, {
    data: {},
  });
}

// Transfer p2p
export async function SearchClientByPhone(countryId: number, phone: string) {
  return await axiosInstance.get(
    `wallettransfer/search?phoneNumber=${phone}&countryId=${countryId}`
  );
}

export async function RequestWalletTransfer(
  userId: string,
  countryCode: number,
  accountId: number,
  phoneNumber: string,
  amount: number,
  currency: string,
  receiverAccountId: number
) {
  return await axiosInstance.post(`wallettransfer/request`, {
    sender: {
      userId,
      accountId,
      currency,
    },
    receiver: {
      countryCode,
      accountId: receiverAccountId,
      phoneNumber,
    },
    amount,
  });
}

export async function TransferToClient(
  amount: number,
  userId: string,
  receiverId: string,
  fromAccountId: number,
  toAccountId: number,
  pinCode: string,
  paymentDetail?: string
) {
  return await axiosInstance.post(`wallettransfer/create`, {
    sender: {
      userId,
      accountId: fromAccountId,
    },
    receiver: {
      userId: receiverId,
      accountId: toAccountId,
    },
    amount,
    twoFactorCode: pinCode,
    paymentDetail,
  });
}

// SEPA
export async function CreateSEPATransfer(
  userId: string,
  accountId: number,
  firstName: string,
  lastName: string,
  receivercurrencyCode: string,
  countryId: number,
  templateName: string,
  bankName: string | null | undefined,
  bic: string | null | undefined,
  iban: string,
  entityType: number,
  isSaveAsTemplate: boolean,
  paymentDetails: string,
  debitAmount: number,
  creditAmount: number,
  exchangeRate: string
) {
  return await axiosInstance.post(`/sepa/create`, {
    senderDetails: {
      userId,
      accountId,
    },
    receiverDetails: {
      lastName,
      firstName,
      currencyCode: receivercurrencyCode,
      countryId,
    },
    sepaTransactionDetails: {
      templateName,
      bankName,
      bic,
      iban,
      entityType,
      isSaveAsTemplate,
      paymentDetails,
      debitAmount,
      creditAmount,
      exchangeRate,
    },
  });
}

export async function IbanChecker(iban: string, countryId?: number) {
  return await axiosInstance.post(`/banking/ibanCheck`, {
    iban,
    countryId,
  });
}

export async function GetSepaTemplates() {
  return await axiosInstance.get(`/sepa/gettemplates`);
}

export async function deleteSepaTemplate(userId: string, templateId: string) {
  return await axiosInstance.delete(`/sepa/deletetemplate`, {
    data: {
      templateId,
      userId,
    },
  });
}

// SEPA FILE UPLOAD
export async function UploadSEPAFile(
  userId: string,
  transferId: number,
  file: any
) {
  return await axios({
    method: "post",
    url: `${baseURL}transferattachments/upload?userId=${userId}&transactionHistoryId=${transferId}`,
    data: {
      file,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    },
  });
}

// Exchange rate
export async function GetRates() {
  return await axiosInstance.get(`/exchange/rates`);
}

export async function CalculatExchange(
  debitAccountId: number,
  creditAccountId: number,
  userId: string,
  debitAmount?: number,
  creditAmount?: number
) {
  return await axiosInstance.post(`/exchange/calculate`, {
    debitAmount,
    creditAmount,
    debitAccountId,
    creditAccountId,
    userId,
  });
}

export async function DoExchange(
  userId: string,
  debitAccountId?: number,
  creditAccountId?: number,
  buyingRate?: number,
  sellingRate?: number,
  debitAmount?: number,
  creditAmount?: number
) {
  return await axiosInstance.post(`/exchange/exchange`, {
    buyingRate,
    sellingRate,
    debitAmount,
    creditAmount,
    debitAccountId,
    creditAccountId,
    userId,
  });
}

// Transaction history

export async function ListTransactionsByDate(
  fromdate: string | undefined,
  toDate: string | undefined,
  userId: string,
  order = "Descending",
  limit = 10,
  offset = 1,
  keyword: string = "",
  accountIds: number[] | string[] = [],
  currencyIds: number[] | string[] = [],
  operationTypes: string[] = []
) {
  let accountIdsToSend = "";
  let operationTypesToSend = "";
  let currencyIdsToSend = "";
  if (accountIds.length) {
    accountIdsToSend = accountIds.map((id) => `&accountIds=${id}`).join("");
  }

  if (operationTypes.length) {
    operationTypesToSend = operationTypes
      .map((type) => `&operationTypes=${type}`)
      .join("");
  }

  if (currencyIds.length) {
    currencyIdsToSend = currencyIds
      .map((currencyId) => `&currencyIds=${currencyId}`)
      .join("");
  }

  if(!fromdate && !toDate) {
    return await axiosInstance.get(
      `/transactionhistory?userId=${userId}&CurrentPage=${offset}&pagesize=${limit}&order=${order}&keyword=${keyword}${accountIdsToSend}${operationTypesToSend}${currencyIdsToSend}`
    );
  }

  return await axiosInstance.get(
    `/transactionhistory?fromdate=${fromdate}&todate=${toDate}&userId=${userId}&CurrentPage=${offset}&pagesize=${limit}&order=${order}&keyword=${keyword}${accountIdsToSend}${operationTypesToSend}${currencyIdsToSend}`
  );
}

export async function getTransferDetails(
  userId: string,
  transactionHistoryId: number,
  operationType: string
) {
  var type = "";

  switch (operationType) {
    case "CurrencyExchange":
      type = "exchangeTransferDetails";
      break;
    case "WalletTransfer":
      type = "wallettransferdetails/sent";
      break;
    case "WalletTransferIn":
      type = "wallettransferdetails/received";
      break;
    case "TransferIn":
      type = "moneytransferdetails/withdrawn";
      break;
    case "TransferOut":
      type = "moneyTransferDetails";
      break;
    case "SepaTransfer":
      type = "sepaTransferDetails";
      break;
    case "BillPayment":
      type = "billpaymentdetails";
      break;
    case "MoneyTransferToBankAccount":
      type = "banktransferdetails";
      break;
    case "eWalletBalanceTopUpWithCard":
      type = "ewallettopupwithcarddetails";
      break;
    case "BankTransferTopUp":
      type = "banktransfertopupdetails";
      break;
    case "CashIn":
      type = "cashintransferdetails";
      break;
    case "CashOut":
      type = "cashouttransferdetails";
      break;
    default:
      return "";
  }

  return await axiosInstance.get(
    `/transactionhistory/${type}?userId=${userId}&transactionHistoryId=${transactionHistoryId}`
  );
}

// EI cards
export async function GetIECards(userId: string) {
  return await axiosInstance.get(`/cards?userId=${userId}`);
}

export async function GetIECardsListing(
  userId: string,
  accountId: string | undefined,
  from: string | undefined,
  to: string | undefined
) {
  return await axiosInstance.get(
    `/cards/transactions?userId=${userId}&accountId=${accountId}&from=${from}&to=${to}`
  );
}

export async function GetBlockedAmounts(userId: string, cardId: string) {
  return await axiosInstance.get(
    `/cards/blocked?userId=${userId}&cardId=${cardId}`
  );
}

export async function BlockIECard(
  userId: string,
  CardN: string,
  reason: string
) {
  return await axiosInstance.post(`/cards/block`, {
    CardN,
    reason,
    userId,
  });
}

export async function UnblockIECard(userId: string, CardN: string) {
  return await axiosInstance.post(`/cards/unblock`, {
    CardN,
    userId,
  });
}

export async function ResetIECardPin(userId: string, CardN: string) {
  return await axiosInstance.post(`/cards/reset/pin`, {
    CardN,
    userId,
  });
}

export async function ConfirmIECardPin(
  userId: string,
  CardN: string,
  requestId: number | null | undefined
) {
  return await axiosInstance.post(`/cards/confirm/pin`, {
    CardN,
    userId,
    requestId,
  });
}

export async function ApplyCard(userId: string) {
  return await axiosInstance.get(`/cards/apply?userId=${userId}`);
}

export async function CheckCardStatus(userId: string) {
  return await axiosInstance.get(
    `/cards/checkapplicationstatus?userId=${userId}`
  );
}

// Image
export async function getImage(name: string) {
  return await axiosInstance.get(`/image/${name}`);
}

export async function getIcon(
  iconType: any,
  type?: string,
  isAbsolute?: boolean
) {
  if (isAbsolute) {
    return await axiosInstance.get(`${iconType}`);
  }
  if (type === "billing") {
    return await axiosInstance.get(`/image/bill/category/${iconType}`);
  }
  if (type === "card") {
    return await axiosInstance.get(`/image/card/${iconType}`);
  }
  return await axiosInstance.get(`/image/icon/${iconType}`);
}

// Bank Transfers
export async function BankTransferTopCountries(userId: string) {
  return await axiosInstance.get(
    `/countrycodes/banktransfers/topcountries/${userId}`
  );
}

export async function OtherCountries(tranType: string) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/countries/${tranType}`
  );
}

export async function getConfiguration(countryId: string | number) {
  return await axiosInstance.get(
    `/moneytransfer/banktransfers/configuration?countryId=${countryId}`
  );
}

export async function CalculateBankTransferAmountAndFee(
  UserId: string,
  DebitAccountId: string | number,
  DebitAmount?: number,
  CreditAmount?: number,
  DestinationOfficeCode?: number,
  CreditCurrency?: string,
  IsBusiness?: boolean,
  ReceiverCountryId?: number
) {
  if (DebitAmount) {
    return await axiosInstance.get(
      `/moneytransfer/banktransfers/calculatecommission?userId=${UserId}&DebitAccountId=${DebitAccountId}&DebitAmount=${DebitAmount}&DestinationOfficeCode=${DestinationOfficeCode}&CreditCurrency=${CreditCurrency}&EntityType=${IsBusiness}&ReceiverCountryId=${ReceiverCountryId}`
    );
  }

  return await axiosInstance.get(
    `/moneytransfer/banktransfers/calculatecommission?userId=${UserId}&DebitAccountId=${DebitAccountId}&CreditAmount=${CreditAmount}&DestinationOfficeCode=${DestinationOfficeCode}&CreditCurrency=${CreditCurrency}&EntityType=${IsBusiness}&ReceiverCountryId=${ReceiverCountryId}`
  );
}

export async function getOffice(officeCode: string | number) {
  return await axiosInstance.get(`/countrycodes/offices/details/${officeCode}`);
}

export async function getOfficesCount(countryId: string | number) {
  return await axiosInstance.get(`countrycodes/moneytransfer/offices/${countryId}/count`);
}

export async function GetBankTransferTemplates() {
  return await axiosInstance.get(`/transfertemplate/banktransfer`);
}

export async function CreateBankTransferRequest(
  accountId: number,
  userId: string,
  creditAmount: number,
  debitAmount: number,
  exchangeRate: number,
  recipientCurrency: string,
  isDataSharingApproved: boolean,
  firstName: string,
  lastName: string,
  countryId: number,
  phoneNumber: string,
  alpha2: string,
  country: string,
  destinationOfficeCode: number,
  accountNumber: string,
  paymentDescription: string,
  transactionType: string,
  parameters: any,
  entityType: boolean
) {
  return await axiosInstance.post(`/moneytransfer/banktransfers/create`, {
    transfer: {
      accountId,
      userId,
      creditAmount,
      debitAmount,
      exchangeRate,
      recipientCurrency,
      // isDataSharingApproved,
    },
    receiver: {
      firstName,
      lastName,
      countryId,
      phoneNumber,
      alpha2,
      country: country,
    },
    destinationOfficeCode,
    accountNumber,
    paymentDescription,
    transactionType,
    parameters,
    entityType
  });
}

export async function ApproveBankTransfer(
  userId: string,
  TransferId: string | number,
  pinCode: string | number,
  isSEPA: boolean
) {
  if (isSEPA) {
    return await axiosInstance.post(`/sepa/approve`, {
      userId,
      TransferId,
      twoFactorCode: pinCode,
    });
  }

  return await axiosInstance.post(`moneytransfer/banktransfers/approve`, {
    userId,
    TransferId,
    twoFactorCode: pinCode,
  });
}

export async function CreateBankTransferTemplate(
  templateName: string,
  countryCodeId: number,
  userId: string,
  bankName: string,
  bic: string,
  accountNumber: string,
  paymentDetails: string,
  additionalCode: string,
  transactionType: string,
  lastName: string,
  firstName: string,
  countryId: number,
  phoneNumber: string,
  entityType: string,
  destinationOfficeCode: number
) {
  return await axiosInstance.post(`/transfertemplate/banktransfer`, {
    templateName,
    countryCodeId,
    userId,
    bankName,
    bic,
    accountNumber,
    paymentDetails,
    additionalCode,
    transactionType,
    receiver: {
      lastName,
      firstName,
      countryId,
      phoneNumber,
    },
    entityType,
    destinationOfficeCode,
  });
}

export async function CardChecker(card: string) {
  return await axiosInstance.post(`/banking/cardCheck`, {
    card,
  });
}

// Cash pickup

export async function CashPickupTransferTopCountries(userId: string) {
  return await axiosInstance.get(
    `/countrycodes/cashpickup/topcountries/${userId}`
  );
}

export async function GetCashPickupTransferTemplates() {
  return await axiosInstance.get(`/transfertemplate/cashpickup`);
}

export async function GetOfficesByTranType(
  countryId: string,
  trantype: string
) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/offices/${countryId}/trantype?trantype=${trantype}`
  );
}

export async function OtherCashPickupCountries(trantype: string) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/countries/${trantype}`
  );
}

export async function GetCountryDirections(countryId: number) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/countries/${countryId}/directions/`
  );
}

export async function GetCountryDirectionOffices(countryId: number, directionCode: number) {
  return await axiosInstance.get(
    `/countrycodes/moneytransfer/countries/${countryId}/directions/${directionCode}`
  );
}

export async function CreateCashPickupTemplate(
  templateName: string,
  firstName: string,
  lastName: string,
  officeCode: number,
  userId: string,
  phoneNumber: string,
  countryCodeId: number
) {
  return await axiosInstance.post(`/transfertemplate/cashpickup`, {
    templateName,
    countryCodeId,
    userId,
    lastName,
    firstName,
    officeCode,
    phoneNumber,
  });
}

// Bill payments
export async function getBillPaymentCategories(userId: string) {
  return await axiosInstance.get(`/bills/categories?userId=${userId}`);
}

export async function getCategories(userId: string) {
  return await axiosInstance.get(`/bills/categories?userId=${userId}`);
}

export async function getCategoryServices(userId: string, categoryId: string) {
  return await axiosInstance.get(
    `/bills/services/${categoryId}?userId=${userId}`
  );
}

export async function searchBillServices(userId: string, keyword: string) {
  return await axiosInstance.get(
    `/bills/services/search?userId=${userId}&keyword=${keyword}`
  );
}

export async function getBillServiceConfiguration(
  userId: string,
  serviceId: string
) {
  return await axiosInstance.get(
    `/bills/services/${serviceId}/configuration?userId=${userId}`
  );
}

export async function checkService(
  userId: string,
  serviceId: string | undefined,
  parameters: any
) {
  return await axiosInstance.post(`/bills/services/check`, {
    userId,
    serviceId,
    parameters,
  });
}

export async function calculateService(
  userId: string,
  serviceId: string | undefined,
  parameters: any,
  amount: number | string,
  accountId: number
) {
  return await axiosInstance.post(`/bills/services/calculate`, {
    userId,
    serviceId,
    parameters,
    amount,
    accountId,
  });
}

export async function payService(
  userId: string,
  serviceId: string,
  requestId: string,
  code: string
) {
  return await axiosInstance.post(`/bills/services/pay`, {
    userId,
    serviceId,
    requestId,
    code,
  });
}

export async function CreateBillPaymentTemplate(
  userId: string,
  transactionId: number
) {
  return await axiosInstance.post(`/transfertemplate/billpayments`, {
    userId,
    transactionId,
  });
}

export async function getBillTemplates() {
  return await axiosInstance.get(`/transfertemplate/billpayments`);
}

export async function calculateBatchServices(
  userId: string,
  services: any,
  accountId?: number
) {
  return await axiosInstance.post(`/bills/services/calculate/batch`, {
    userId,
    accountId,
    services,
  });
}

export async function payBatchServices(userId: string, processes: any) {
  return await axiosInstance.post(`/bills/services/pay/batch`, {
    userId,
    processes,
  });
}

export async function toggleEmail(
  clientId: string,
  enableEmailNotification: boolean
) {
  return await axiosInstance.post(`/profile/notification/toggleemail`, {
    clientId,
    enableEmailNotification,
  });
}

export async function toggleSms(
  clientId: string,
  enableSmsNotification: boolean
) {
  return await axiosInstance.post(`/profile/notification/togglesms`, {
    clientId,
    enableSmsNotification,
  });
}

export async function togglePush(
  clientId: string,
  enablePushNotification: boolean
) {
  return await axiosInstance.post(`/profile/notification/togglepush`, {
    clientId,
    enablePushNotification,
  });
}

export async function toggleMarketingupdates(
  clientId: string,
  enableMarketingUpdates: boolean
) {
  return await axiosInstance.post(
    `/profile/notification/togglemarketingupdates`,
    {
      clientId,
      enableMarketingUpdates,
    }
  );
}

export async function getSettings(userId?: string) {
  return await axiosInstance.get(`/profile/settings?userId=${userId}`);
}

// Deactivate account

export async function initiateDeactivation(
  authType: string,
  token: string,
  userId: string
) {
  return await axiosInstance.post(`/profile/deactivation/initiate`, {
    authType,
    token,
    userId,
  });
}

export async function confirmDeactivation(
  userId: string,
  twoFactorCode: string
) {
  return await axiosInstance.post(`/profile/deactivation/confirm`, {
    twoFactorCode,
    userId,
  });
}

// Template Deletions

export async function deleteTemplateType(
  userId: string,
  templateId: any,
  type: string
) {
  var typeVar = "";

  switch (type) {
    case "Bank Transfer":
      typeVar = "banktransfer";
      break;
    case "Money Transfer":
      typeVar = "cashpickup";
      break;
    case "Bill Payment":
      typeVar = "billpayments";
      break;
    default:
      return "";
  }

  return await axiosInstance.delete(`/transfertemplate/${typeVar}`, {
    data: {
      templateId,
      userId,
    },
  });
}

export async function renameTemplateType(
  userId: string,
  templateId: string,
  newTemplateName: string,
  type: string
) {
  var typeVar = "";

  switch (type) {
    case "Bank Transfer":
      typeVar = "banktransfer";
      break;
    case "Money Transfer":
      typeVar = "cashpickup";
      break;
    case "Bill Payment":
      typeVar = "billpayments";
      break;
    default:
      return "";
  }

  return await axiosInstance.put(`/transfertemplate/${typeVar}/rename`, {
    templateId,
    newTemplateName,
    userId,
  });
}

// rename account

export async function renameAccount(
  userId: string,
  accountId: number,
  walletId: number,
  newAccountName: string
) {
  return await axiosInstance.post(`/account/renameaccount`, {
    userId,
    walletId,
    accountId,
    newAccountName,
  });
}

// Card limits

export async function changeLimit(
  userId: string,
  CardNFrom: string,
  CardNTo: string,
  amount: number
) {
  return await axiosInstance.post(`/cards/limit/change`, {
    userId,
    CardNFrom,
    CardNTo,
    amount,
  });
}

export async function getCardLimit(
  userId: string,
  CardNumberFrom: string,
  CardNumberTo: string,
  Amount: string
) {
  return await axiosInstance.get(
    `/cards/limit/info?userId=${userId}&CardNumberFrom=${CardNumberFrom}&CardNumberTo=${CardNumberTo}&amount=${Amount}`
  );
}

// Profile

export async function getProfilePicture(userId?: string) {
  // return await axiosInstance.get(`/profile/profilePicture?userid=${userId}`);
  return await axios({
    method: "get",
    url: `${baseURL}profile/profilePicture?userid=${userId}`,
    headers: {
      responseType: "blob",
      // responseType: "arrayBuffer",
      Authorization: getToken(),
    },
  });
}

export async function setProfilePicture(userId: string, file: any) {
  return await axios({
    method: "post",
    url: `${baseURL}profile/profilePicture?userid=${userId}`,
    data: file,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    },
  });
}

export async function putProfilePicture(userId: string, file: any) {
  return await axios({
    method: "put",
    url: `${baseURL}profile/profilePicture?userid=${userId}`,
    data: file,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    },
  });
}

export async function deleteProfilePicture(userId: string) {
  return await axiosInstance.delete(`profile/profilePicture?userid=${userId}`, {
    // data: {
    //   templateId,
    //   userId,
    // },
  });
}

export async function toggleProfilePicture(
  userId: string,
  enablePublicPicture: boolean
) {
  return await axiosInstance.post(`/profile/togglepublicpicture`, {
    userId,
    enablePublicPicture,
  });
}

// Change Email
export async function changeEmailInitiate(
  userId: string,
  newEmail: string,
  password: string
) {
  return await axiosInstance.post(`/profile/email/change/initiate`, {
    newEmail,
    authorizationDetails: {
      authType: "Password",
      token: password,
      userId,
    },
  });
}

export async function changeEmailVerify(
  userId: string,
  twoFactorCode: string,
  newEmail: string
) {
  return await axiosInstance.post(`/profile/email/change/verify`, {
    newEmail,
    twoFactorCode,
    userId,
  });
}

export async function changeEmailConfirm(
  userId: string,
  refreshToken: string,
  twoFactorCode: string
) {
  return await axiosInstance.post(`/profile/email/change/confirm`, {
    code: twoFactorCode,
    refreshToken,
    userId,
  });
}

// Change Phone
export async function changePhoneInitiate(
  userId: string,
  newPhoneNumber: string,
  password: string,
  countryCodeId: number
) {
  return await axiosInstance.post(`/profile/phone/change/initiate`, {
    newPhoneNumber,
    countryCodeId,
    authorizationDetails: {
      authType: "Password",
      token: password,
      userId,
    },
  });
}

export async function changePhoneVerify(
  userId: string,
  twoFactorCode: string,
  newPhoneNumber: string,
  countryCodeId: number
) {
  return await axiosInstance.post(`/profile/phone/change/verify`, {
    newPhoneNumber,
    twoFactorCode,
    userId,
    countryCodeId,
  });
}

export async function changePhoneConfirm(
  userId: string,
  refreshToken: string,
  twoFactorCode: string
) {
  return await axiosInstance.post(`/profile/phone/change/confirm`, {
    twoFactorCode,
    refreshToken,
    userId,
  });
}

// Unverified mail
export async function unverifiedMailRequest(userNameOrEmail: string) {
  return await axiosInstance.post(`/unverifiedemailchange/request`, {
    userNameOrEmail,
  });
}

export async function unverifiedMailVerify(
  userNameOrEmail: string,
  code: string
) {
  return await axiosInstance.post(`/unverifiedemailchange/verify`, {
    userNameOrEmail,
    code,
  });
}

export async function unverifiedMailConfirm(
  token: string,
  newEmail: string,
  oldEmail: string
) {
  return await axiosInstance.post(`/unverifiedemailchange/confirm`, {
    token,
    newEmail,
    oldEmail,
  });
}

// Notifications
export async function getNotifications(
  userId: string,
  currentPage: number,
  pageSize: number
) {
  return await axiosInstance.get(
    `/notification?userId=${userId}&currentPage=${currentPage}&pagesize=${pageSize}`
  );
}

export async function getUnreadNotifications(
  userId: string,
  currentPage: number,
  pageSize: number
) {
  return await axiosInstance.get(
    `/notification/unread?userId=${userId}&currentPage=${currentPage}&pagesize=${pageSize}`
  );
}

export async function markAsRead(userId: string, notificationId: number) {
  return await axiosInstance.post(`/notification/read`, {
    userId,
    notificationId,
  });
}

export async function markAllAsRead(userId: string) {
  return await axiosInstance.post(
    `/notification/read/all?userId=${userId}`,
    {}
  );
}

export async function deleteNotification(
  userId: string,
  notificationId: number
) {
  return await axiosInstance.delete(`/notification`, {
    data: {
      userId,
      notificationId,
    },
  });
}

// Branches

export async function getBranches(userId: string) {
  return await axiosInstance.get(`/branch/locations?userId=${userId}`);
}

// Loans
export async function getLoanDetails(userId: string) {
  return await axiosInstance.get(`/loans/details?userId=${userId}`);
}

export async function getLoanSchedule(userId: string) {
  return await axiosInstance.get(`/loans/schedule?userId=${userId}`);
}

export async function getNextPayment(userId: string) {
  return await axiosInstance.get(`/loans/next?userId=${userId}`);
}

// Deeplink

export async function getWelcomeDeepLink() {
  return await axiosInstance.get(`/links/deeplink`);
}

export async function handlePdfDownloadService(
  userId: string,
  transferId: number
) {
  return await axiosInstance.get(
    `/transactionhistory/generatepdf?userId=${userId}&transactionHistoryId=${transferId}`,
    {
      responseType: "arraybuffer",
    }
  );
}

export async function handleLoanDownloadService(userId: string) {
  return await axiosInstance.get(`/loans/schedule/download?userId=${userId}`, {
    responseType: "arraybuffer",
  });
}

export async function handleStatementDownloadService(
  userId: string,
  date1: string,
  date2: string,
  chosenAccountId: string
) {
  return await axiosInstance.get(
    `/statement/pdf?userid=${userId}&fromdate=${date1}&todate=${date2}&accountid=${chosenAccountId}`,
    {
      responseType: "arraybuffer",
    }
  );
}


export async function handleCardStatementDownloadService(
  userId: string,
  date1: string,
  date2: string,
  cardId: string
) {
  return await axiosInstance.get(
    `/cards/pdf?userid=${userId}&fromdate=${date1}&todate=${date2}&cardId=${cardId}`,
    {
      responseType: "arraybuffer",
    }
  );
}
