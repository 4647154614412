import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    accounts: [],
    rates: [],
    profilePhoto: "",
    lang: "",
    token: "",
    balances: [],
    registrationData: {},
    resetPasswordData: {},
    isRegistrationPhoneVerified: false,
  },
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
    },
    setAccounts(state, { payload }) {
      state.accounts = payload;
    },
    setBalances(state, { payload }) {
      state.balances = payload;
    },
    setRates(state, { payload }) {
      state.rates = payload;
    },
    setProfilePhoto(state, { payload }) {
      state.profilePhoto = payload;
    },
    setLang(state, { payload }) {
      state.lang = payload;
    },
    setToken(state, { payload }) {
      state.token = payload;
    },
    setPhoneVerification(state, { payload }) {
      state.isRegistrationPhoneVerified = payload;
    },
    setRegistrationData(state, { payload }) {
      if (payload.reset) {
        state.registrationData = {};
      } else {
        state.registrationData = {
          ...state.registrationData,
          ...payload,
        };
      }
    },
    setResetPasswordData(state, { payload }) {
      if (payload.reset) {
        state.resetPasswordData = {};
      } else {
        state.resetPasswordData = {
          ...state.resetPasswordData,
          ...payload,
        };
      }
    },
  },
});

export const {
  setUser,
  setAccounts,
  setRates,
  setProfilePhoto,
  setLang,
  setToken,
  setBalances,
  setRegistrationData,
  setResetPasswordData,
  setPhoneVerification,
} = authSlice.actions;

export default authSlice.reducer;
