import { useTranslation } from "react-i18next";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import FlexWrapper from "../../../components/FlexWrapper";
import Img from "../../../components/Img";
import Box from "../../../components/Tailwind/Box";
import Title from "../../../components/Tailwind/Title";
import Text from "../../../components/Tailwind/Text";

export const UnderReview = ({ onNextClick }: any) => {
  const { t } = useTranslation();

  return (
    <Box className="shadow-[0px_2px_3px_0px_rgba(111,118,137,0.10)] !border-0 mx-4 md:mx-0 ">
      <Title variant="h3" className="text-center mt-4 mb-6">
        {t("registration.bls.underreview")}
      </Title>
      <div className="text-center">
        <Img
          className="max-w-[225px] py-10 mb-6"
          src="/bls/loop.gif"
          alt="Loop"
        />
      </div>

      <Box className="!bg-[#F7F7F7] !p-4 max-w-[700px] mx-auto ">
        <Title variant="title" className="mb-2">
          {t("registration.bls.whatsnext")}
        </Title>
        <Text variant="body2" className="mb-2 text-[#56575B]">
          {t("registration.bls.underreviewtext1")}
        </Text>
        <Text variant="body2" className="mb-2 text-[#56575B]">
          {t("registration.bls.underreviewtext2")}
        </Text>
        <FlexWrapper gap="8px" className="py-[10px]" mb="0">
          <ErrorOutlineOutlinedIcon style={{ fill: "#E02629", width: 18 }} />
          <a href="/contact" target="_blank">
            <Text
              variant="body3"
              className="uppercase underline hover:no-underline text-[#E02629] font-medium caps"
            >
              {t("contact.title")}
            </Text>{" "}
          </a>
        </FlexWrapper>
      </Box>

      <button
        className="w-[380px] h-[38px] leading-4 mt-10 max-w-full mx-auto btn btn-red text-base mb-4 caps"
        onClick={onNextClick}
      >
        {t("common.backtohome")}
      </button>
    </Box>
  );
};
