import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthLayout, MainLayout, PlainLayout } from "./layout";
import {
  HomeSection,
  Login,
  Exchange,
  FAQ,
  FAQcomponent,
  NotFound,
  TopupWallet,
  TransactionHistory,
  BankTransfer,
  CashPickup,
  CancelTransfer,
  EditTransfer,
  ReceiveTransfer,
  BillPayments,
  Service,
  TemplatesInner,
  WalletToWallet,
  Manage,
  WithdrawConsent,
  Statements,
  Privacy,
  Registration,
  ConfirmEmail,
  Profile,
  Final,
  AfterFinal,
  CardTopupResult,
  Unsubscribe,
  Welcome,
  BLS,
} from "./containers";
import { getStorageObject, getToken } from "./utils/storage";
import { setLang, setUser } from "./redux/authSlice";
import { FAQLinks } from "./containers/FAQ/data";
import { langSelector } from "./redux/selectors";
import About from "./containers/About";
import Contact from "./containers/ContactSupport";
import Forgot from "./containers/auth/forgot/Forgot";

const RoutesComp = () => {
  const loggedIn = getToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (getStorageObject("user")) {
      dispatch(setUser(getStorageObject("user")));
    }
    dispatch(setLang(localStorage.getItem("i18nextLng")));
  }, [dispatch]);

  const lang = useSelector(langSelector);

  useEffect(() => {
    if (lang === "ka") {
      document.body.classList.add("notosans");
    } else {
      document.body.classList.remove("notosans");
    }
  }, [lang]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            loggedIn ? (
              <Navigate to={`/home${window.location.search}`} />
            ) : (
              <Navigate to={`/login${window.location.search}`} />
            )
          }
        />

        <Route
          path="/registration"
          exact
          element={<AuthLayout component={Registration} />}
        />
        <Route path="/login" element={<AuthLayout component={Login} />} />
        <Route path="/accountstatus" element={<AuthLayout component={BLS} />} />
        <Route path="/confirmemail" element={<ConfirmEmail />} />

        <Route path="home" element={<MainLayout component={HomeSection} />} />

        <Route
          path="/topup-wallet"
          element={<MainLayout hideRightSide component={TopupWallet} />}
        />
        <Route
          path="/topup/:id"
          element={<MainLayout hideRightSide component={CardTopupResult} />}
        />
        <Route
          path="/billpayments"
          element={<MainLayout hideRightSide component={BillPayments} />}
        />
        <Route
          path="/billpayments/service/:serviceid"
          element={<MainLayout hideRightSide component={Service} />}
        />
        <Route
          path="/billpayments/templates"
          element={<MainLayout hideRightSide component={TemplatesInner} />}
        />

        <Route
          path="/banktransfer"
          element={<PlainLayout component={BankTransfer} />}
        />

        <Route
          path="/cashpickup"
          element={<PlainLayout component={CashPickup} />}
        />

        <Route
          path="/edittransfer"
          element={<PlainLayout component={EditTransfer} />}
        />

        <Route
          path="/withdraw"
          element={<PlainLayout component={ReceiveTransfer} />}
        />

        <Route path="/consent" element={<WithdrawConsent />} />

        <Route
          path="/canceltransfer"
          element={<PlainLayout component={CancelTransfer} />}
        />

        <Route
          path="/wallettransfer"
          element={<PlainLayout component={WalletToWallet} />}
        />

        <Route
          path="/privacy-conditions"
          element={<PlainLayout noauth component={Privacy} />}
        />

        <Route
          path="/statements"
          element={<MainLayout hideRightSide component={Statements} />}
        />
        <Route
          path="/exchange"
          element={<MainLayout hideRightSide component={Exchange} />}
        />
        <Route
          path="/history"
          element={<MainLayout hideRightSide component={TransactionHistory} />}
        />

        <Route
          path="/manage"
          element={<MainLayout hideRightSide component={Manage} />}
        />
        <Route
          path="/profile"
          element={<MainLayout hideRightSide component={Profile} />}
        />

        <Route exact path="/faq" element={<MainLayout component={FAQ} />} />

        <Route
          exact
          path="/verification"
          element={<AuthLayout component={Final} />}
        />

        <Route
          exact
          path="/verification/final"
          element={<AuthLayout component={AfterFinal} />}
        />

        <Route
          path="/about"
          element={<PlainLayout noauth component={About} />}
        />

        <Route
          path="/contact"
          element={<PlainLayout noauth component={Contact} />}
        />

        <Route path="/forgot" element={<AuthLayout component={Forgot} />} />

        {FAQLinks.map((item, index) => (
          <Route
            key={index}
            path={`/faq/${item.to}`}
            element={<MainLayout component={FAQcomponent} />}
          />
        ))}

        <Route path="/unsubscribe" element={<Unsubscribe />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComp;
