import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BaseModal from "../components/BaseModal";
import Text from "../components/Text/text";
import Title from "../components/Text/title";
import { media } from "../utils/theme";

const ContentWrapper = styled.div`
  padding: 1rem 1.5rem 1rem;
  border-radius: 0 0 11px 11px;
  max-width: 560px;

  @media (max-width: 553px) {
    max-width: 100%;
    padding: 1rem;
    border-radius: 4px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;

  button {
    width: 120px;
    margin-left: 0.5rem;
  }

  ${media.lessThan("small")} {
    border-top: 1px solid #e4e4e4;

    button {
      width: 100%;
      margin-left: 0;
      margin-top: 1.5rem;

      &:nth-child(2) {
        margin-left: 0.5rem;
      }
    }
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  border: none;
  border-radius: 4px;

  img {
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
    max-width: 1.1rem;
  }
`;

const PlainButton = styled.button`
  margin-bottom: 0.25rem;
  border: 1px solid #e4e4e4 !important;
  background: #fff;
  color: var(--text-color);
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background: #fbfbfb;
  }
`;

export interface InterruptionModalProps {
  isOpen: boolean;
  text?: string;
  title?: string;
  actionText?: string;
  closeText?: string;
  onStayClick: () => void;
  onCloseButtonClick?: () => void;
  onCloseClick: () => void;
  children?: React.ReactNode;
  mobileWidth?: string;
  type?: string;
}

const InterruptionModal = ({
  isOpen,
  text,
  title,
  actionText,
  closeText,
  onCloseButtonClick,
  onStayClick,
  onCloseClick,

  children,
}: InterruptionModalProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onCloseClick}>
      <ContentWrapper>
        <Title color="#171922" weight="500" mb="0">
          {title}
        </Title>
        <br />
        <Text size={"14px"} mb="1.25rem" lh="20px">
          {text}
        </Text>
        {children}
        <ButtonsWrapper>
          <BackButton
            className="btn btn-red uppercase !rounded"
            onClick={onStayClick}
          >
            {actionText || t("common.stay")}
          </BackButton>
          <PlainButton
            className="btn uppercase"
            onClick={onCloseButtonClick || onCloseClick}
          >
            {closeText || t("common.close")}
          </PlainButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default InterruptionModal;
